import React from 'react'

type PropsType = {
  iconPath: string
  className?: string
  alt?: string
}

export function PlazusIcon({ alt, iconPath, className }: PropsType) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${iconPath}`}
      alt={alt}
      className={`${className || ''}`}
    />
  )
}

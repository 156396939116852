import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { PlazusButton } from '../PlazusButton'
import { DropDownTypesType, navBarConfigs } from '../../constants/navBar'
import { useAppDispatch } from '../../redux/hooks'
import { setTimeGap } from '../../redux/slices/timeGapFilter'
import { defaultExport } from '../../redux/thunks/export'
import './index.scss'

type PropsType = {
  dropDownType: keyof DropDownTypesType
  className?: string
  openModal?: () => void
}

export function DropDown({ dropDownType, className, openModal }: PropsType) {
  const dispatch = useAppDispatch()
  const { toggler, listItems, setSelectedLabel } = navBarConfigs[dropDownType]
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false)
  const [togglerText, setTogglerText] = useState<string>(toggler.text)

  function changeDropDownListState() {
    setIsDropDownOpened(!isDropDownOpened)
  }

  function onListItemClick(label: string, timeGap?: number) {
    if (setSelectedLabel) setTogglerText(label)
    if (typeof timeGap === 'number') {
      dispatch(setTimeGap(timeGap))
    }
  }

  return (
    <Dropdown className={className || ''}>
      <Dropdown.Toggle as={'div'}>
        <PlazusButton
          {...toggler}
          text={togglerText}
          className={`${isDropDownOpened ? 'dropdown-active' : ''}`}
          onClick={changeDropDownListState}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        {listItems.map(({ label, timeGap, action }, index) => {
          return (
            <Dropdown.Item
              key={index}
              as="li"
              onClick={() => {
                if (timeGap || timeGap === 0) {
                  onListItemClick(label, timeGap)
                } else if (action === 'defaultExport') {
                  dispatch(defaultExport())
                } else if (action === 'openModal' && openModal) {
                  openModal()
                }
              }}
            >
              {label}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

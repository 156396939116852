import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getNegativeParticipantsByAge,
  getNegativeParticipantsByProvince,
  getTotalInvalidNegativePositiveResults,
} from '../../../redux/selectors'
import { getBarChartStyle } from '../../../utils/styleUtils'
import { VerticalBarChart } from '../../charts/VerticalBarChart'
import { SectionHeader } from '../../SectionHeader'

export function NegativeByProvince() {
  const { negative, positive, invalid } = useAppSelector(
    getTotalInvalidNegativePositiveResults
  )

  const chartDataByProvince = useAppSelector(getNegativeParticipantsByProvince)
  const chartDataByAge = useAppSelector(getNegativeParticipantsByAge)

  return (
    <Container fluid={true} className="graph-container">
      <SectionHeader
        title="Total Negative Results"
        negative={negative}
        total={positive.total + negative.total + invalid.total}
      />
      <Row className="justify-content-center mb-4">
        <Col
          md={12}
          lg={10}
          xl={7}
          xxl={7}
          style={getBarChartStyle(chartDataByProvince.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByProvince}
            legendTitle="Total Test Results and Negative Test Results by Province"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          md={10}
          lg={6}
          xl={3}
          xxl={3}
          style={getBarChartStyle(chartDataByAge.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByAge}
            legendTitle="Total Test Results and Negative Test Results by Age"
          />
        </Col>
      </Row>
    </Container>
  )
}

import React from 'react'
import { TooltipItem } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { LineChartUnionType } from '../../../types/charts'
import { chartConfig } from '../../../constants/charts'

const { chartColors, font } = chartConfig

type PropsType<T> = {
  data: T
  legendTitle: string
  height: number
  stepSize?: number
}

export function LineChart<T extends LineChartUnionType>({
  data,
  legendTitle,
  height,
  stepSize,
}: PropsType<T>) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      datalabels: {
        color: chartColors.transparent,
      },
      legend: {
        align: 'center' as const,
        position: 'bottom' as const,
        reverse: true,
        labels: {
          usePointStyle: true,
          color: chartColors.black,
          font: { family: font, size: 12, weight: 'bold' },
          boxWidth: 6,
          boxHeight: 6,
        },
      },
      title: {
        display: true,
        text: legendTitle,
        font: { family: font, size: 14 },
        color: chartColors.black,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          title: () => '',
          afterLabel: () => '',
          label: (tooltipItem: TooltipItem<'line'>) => {
            const tooltipValue =
              data?.datasets[tooltipItem.datasetIndex]?.data[
                tooltipItem.dataIndex
              ] || 0
            return `${Math.round(tooltipValue)}`
          },
        },
        backgroundColor: chartColors.white,
        bodyColor: chartColors.black,
        bodyFont: { family: font, size: 14 },
        displayColors: true,
        caretPadding: 5,
        mode: 'index' as const,
        cornerRadius: 3,
        borderColor: chartColors.greyTooltip,
        borderWidth: 1,
      },
    },
    element: {
      point: {
        radius: 0,
        hitRadius: 0,
      },
    },
    scales: {
      yAxis: {
        display: true,
        stacked: true,
        grid: {
          display: true,
        },
        ...(stepSize && {
          ticks: {
            stepSize,
          },
        }),
      },
      xAxis: {
        ticks: {
          font: { family: font, size: 14 },
          color: chartColors.black,
        },
        min: 0,
        grid: {
          display: true,
        },
      },
    },
  }

  return <Line height={height} options={options} data={data} />
}

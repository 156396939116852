import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { getTimeGapDates } from '../../../redux/selectors'
import {
  fetchAppByLanguage,
  fetchAppOpened,
  fetchConnectOnCallStatusResults,
  fetchConnectOnCallTotalResults,
  fetchDeactivatedApp,
  fetchParticipantsResultsByAge,
  fetchParticipantsResultsByProvince,
  fetchParticipantTestsResultsByProvince,
  fetchPositiveVsInvalidResultsByAge,
  fetchPositiveVsInvalidResultsByProvince,
  fetchPositivityRateByAge,
  fetchPositivityRateByProvince,
  fetchQuestionnaireByTypeResults,
  fetchQuestionnaireOverTimeResults,
  fetchTotalPositiveAndInvalidResults,
  fetchUsersFunelResults,
  fetchUsersResultByAge,
  fetchUsersResultByProvince,
} from '../../../redux/thunks/dashboard'
import { AppByLanguage } from '../AppByLanguage'
import { AppOpened } from '../AppOpened'
import { ConnectOnCall } from '../ConnectOnCall'
import { NegativeByProvince } from '../NegativeByProvince'
import { ParticipantsTestsByProvince } from '../ParticipantsTestsByProvince'
import { PositiveAndInvalidByProvince } from '../PositiveAndInvalidByProvince'
import { PositivityRate } from '../PositivityRate'
import { QuestionareResult } from '../QuestionareResult'
import { UserEngagementFunnel } from '../UserEngagementFunnel'

export function SectionsContainer() {
  const dispatch = useAppDispatch()
  const { startDate, endDate } = useAppSelector(getTimeGapDates)

  useEffect(() => {
    const payload = {
      body: {
        from: startDate,
        to: endDate,
      },
    }

    dispatch(fetchPositiveVsInvalidResultsByProvince(payload))
    dispatch(fetchParticipantTestsResultsByProvince(payload))
    dispatch(fetchTotalPositiveAndInvalidResults(payload))
    dispatch(fetchParticipantsResultsByProvince(payload))
    dispatch(fetchPositiveVsInvalidResultsByAge(payload))
    dispatch(fetchQuestionnaireByTypeResults(payload))
    dispatch(fetchParticipantsResultsByAge(payload))
    dispatch(fetchPositivityRateByProvince(payload))
    dispatch(fetchUsersResultByProvince(payload))
    dispatch(fetchPositivityRateByAge(payload))
    dispatch(fetchUsersResultByAge(payload))
    dispatch(fetchAppOpened(payload))

    dispatch(fetchQuestionnaireOverTimeResults())
    dispatch(fetchConnectOnCallStatusResults())
    dispatch(fetchConnectOnCallTotalResults())
    dispatch(fetchUsersFunelResults())
    dispatch(fetchDeactivatedApp())
    dispatch(fetchAppByLanguage())
  }, [startDate, endDate])

  return (
    <>
      <NegativeByProvince />
      <PositiveAndInvalidByProvince />
      <PositivityRate />
      <ParticipantsTestsByProvince />
      <AppOpened />
      <AppByLanguage />
      <UserEngagementFunnel />
      <QuestionareResult />
      <ConnectOnCall />
    </>
  )
}

import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { getAccessToken } from '../utils'

export function setAccessTokenToRequestHeader(
  request: AxiosRequestConfig<AxiosRequestHeaders>
) {
  const accessToken = getAccessToken()
  if (accessToken && request.headers) {
    request.headers.authorization = `Bearer ${accessToken}`
  }
  return request
}

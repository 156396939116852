import React from 'react'
import { Navigate } from 'react-router-dom'
import { accessTokenStorageKey } from '../../constants/common'
import { routes } from '../../routing'

type PropsType = {
  element: React.FC
}

export function ProtectedPage(props: PropsType) {
  const { element: Element } = props
  const isLoggedIn = !!sessionStorage.getItem(accessTokenStorageKey)
  return isLoggedIn ? <Element /> : <Navigate replace to={routes.login.path} />
}

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import { getParticipantsTestsByProvince } from '../../../redux/selectors'
import { getBarChartStyle } from '../../../utils/styleUtils'
import { StackbarChart } from '../../charts/StackbarChart'

export function ParticipantsTestsByProvince() {
  const chartDataByProvince = useAppSelector(getParticipantsTestsByProvince)

  return (
    <Container fluid={true} className="graph-container">
      <Row className="justify-content-center">
        <Col
          md={12}
          lg={10}
          xl={7}
          xxl={7}
          style={getBarChartStyle(chartDataByProvince.labels.length)}
        >
          <StackbarChart
            data={chartDataByProvince}
            legendTitle="% of Testers Who Used 1, 2, 3 or more Tests Per Province"
          />
        </Col>
      </Row>
    </Container>
  )
}

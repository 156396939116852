import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { PositiveVsInvalidResults } from '../../../../types/api'
import { getDatasetDataItem, getXAxisLabels } from '../../../../utils'

const { chartColors } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<string>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: PositiveVsInvalidResults) {
  const labels = getXAxisLabels(data.invalid, data.positive)

  const positive = getDatasetDataItem(
    data.positive,
    labels,
    'Positive',
    chartColors.blue
  )

  const invalid = getDatasetDataItem(
    data.invalid,
    labels,
    'Invalid',
    chartColors.yellow
  )

  return {
    labels,
    datasets: [positive, invalid],
  }
}

export const positiveVsInvalidByProvinceSlice = createSlice({
  name: 'PositiveInvalidByProvince',
  initialState,
  reducers: {
    setPositiveVsInvalidByProvince: (
      state,
      { payload }: PayloadAction<PositiveVsInvalidResults>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setPositiveVsInvalidByProvince } =
  positiveVsInvalidByProvinceSlice.actions

export const { reducer: positiveVsInvalidByProvince } =
  positiveVsInvalidByProvinceSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeactivatedAppStateType,
  DeactivatedAppsResults,
} from '../../../types/api'

const initialState: DeactivatedAppStateType = {
  data: {
    deactivated: '',
    percent: 0,
    total: 0,
  },
}

export const deactivatedAppSlice = createSlice({
  name: 'deactivatedApp',
  initialState,
  reducers: {
    setDeactivatedApp: (
      state,
      { payload }: PayloadAction<DeactivatedAppsResults>
    ) => {
      state.data = payload
    },
  },
})

export const { setDeactivatedApp } = deactivatedAppSlice.actions

export const { reducer: deactivatedApp } = deactivatedAppSlice

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getAppOpened,
  getUsersResultsByAge,
  getUsersResultsByProvince,
} from '../../../redux/selectors'
import { getHorizontalBarChartStyle } from '../../../utils/styleUtils'
import { HorizontalBarChart } from '../../charts/HorizontalBarChart'
import { LineChart } from '../../charts/LineChart'

export function AppOpened() {
  const appOpenedChartData = useAppSelector(getAppOpened)
  const usersResultsByAge = useAppSelector(getUsersResultsByAge)
  const { total, ...usersResultsByProvince } = useAppSelector(
    getUsersResultsByProvince
  )

  return (
    <Container fluid={true} className="graph-container">
      <Row className="mb-4">
        <Col>
          <LineChart
            height={300}
            data={appOpenedChartData}
            legendTitle="Application Opened"
            stepSize={appOpenedChartData.stepSize}
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={12}
          md={6}
          style={getHorizontalBarChartStyle(
            usersResultsByProvince.labels.length
          )}
        >
          <HorizontalBarChart
            data={usersResultsByProvince}
            legendTitle={`Number of Participants who have created a Profile by Province (Total: ${total})`}
            dalaLabelValueVsPercent={true}
            isPercentFirst={true}
          />
        </Col>
        <Col
          sm={12}
          md={6}
          style={getHorizontalBarChartStyle(usersResultsByAge.labels.length)}
        >
          <HorizontalBarChart
            data={usersResultsByAge}
            legendTitle="Number of Participants who have created a profile by age"
            dalaLabelValueVsPercent={true}
            isPercentFirst={true}
          />
        </Col>
      </Row>
    </Container>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { PositivityRateResults } from '../../../../types/api'
import { getDatasetDataItem, getXAxisLabelsWithAge } from '../../../../utils'

const { chartColors, doubleBar } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<Array<string>>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: PositivityRateResults) {
  const labels = getXAxisLabelsWithAge()

  const positive = getDatasetDataItem(
    data.positive,
    labels,
    'Positive',
    chartColors.orange,
    true
  )

  const dataset = {
    ...positive,
    ...doubleBar,
  }

  return {
    labels,
    datasets: [dataset],
  }
}

export const positivityRateByAgeSlice = createSlice({
  name: 'positivityRateByAge',
  initialState,
  reducers: {
    setPositivityRateByAge: (
      state,
      { payload }: PayloadAction<PositivityRateResults>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setPositivityRateByAge } = positivityRateByAgeSlice.actions

export const { reducer: positivityRateByAge } = positivityRateByAgeSlice

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getPositiveVsInvalidByAge,
  getPositiveVsInvalidByProvince,
  getTotalInvalidNegativePositiveResults,
} from '../../../redux/selectors'
import { getBarChartStyle } from '../../../utils/styleUtils'
import { VerticalBarChart } from '../../charts/VerticalBarChart'
import { SectionHeader } from '../../SectionHeader'

export function PositiveAndInvalidByProvince() {
  const { negative, positive, invalid } = useAppSelector(
    getTotalInvalidNegativePositiveResults
  )

  const chartDataByProvince = useAppSelector(getPositiveVsInvalidByProvince)
  const chartDataByAge = useAppSelector(getPositiveVsInvalidByAge)

  return (
    <Container fluid={true} className="graph-container">
      <SectionHeader
        title="Total Positive and Invalid Results"
        positive={positive}
        invalid={invalid}
        total={positive.total + negative.total + invalid.total}
      />
      <Row className="justify-content-center mb-4">
        <Col
          md={12}
          lg={10}
          xl={7}
          xxl={7}
          style={getBarChartStyle(chartDataByProvince.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByProvince}
            legendTitle="All Total Positive and Invalid Test results by Province"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          md={10}
          lg={6}
          xl={3}
          xxl={3}
          style={getBarChartStyle(chartDataByAge.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByAge}
            legendTitle="All Total Positive and Invalid Test results by Age"
          />
        </Col>
      </Row>
    </Container>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { connectOnCallByStatusConfig } from '../../../constants/charts'
import {
  ConnectionOnCallByStatusDatasetItemType,
  ConnectionOnCallByStatusStateType,
  ConnectOnCallByStatusResult,
  DatesResult,
  DateValuePair,
} from '../../../types/api'
import {
  addEmptyValuesToArray,
  fillDatesSpaces,
  getLabelsForChart,
  mergeDates,
  numberFilter,
  percentFilter,
} from '../../../utils'

const initialState: ConnectionOnCallByStatusStateType = {
  data: {
    stepSize: 0,
    labels: [],
    datasets: [],
  },
}

const labelStatuses: { [key: string]: string } = {
  negative: 'Negative',
  positive: 'Positive',
  invalid: 'Invalid',
  noresult: 'No Result',
}

function buildLabel(name: string, data: DatesResult) {
  return `${percentFilter(data.percentage as number)} | ${numberFilter(
    data.total
  )} | ${labelStatuses[name]}`
}

function normalizeData(payload: ConnectOnCallByStatusResult) {
  const goToWeeks = false

  const fullDatesList = Object.values(payload).reduce(
    (acc: Array<DateValuePair>, curr: DatesResult): Array<DateValuePair> => [
      ...acc,
      ...curr.days,
    ],
    []
  )

  const uniqueDates = fillDatesSpaces(mergeDates(fullDatesList))

  const labels = getLabelsForChart(uniqueDates, goToWeeks)

  const statusesData: {
    [key in keyof ConnectOnCallByStatusResult]: Array<number>
  } = Object.entries(payload).reduce(
    (acc, [statusName, statusData]) => {
      return {
        ...acc,
        [statusName]: addEmptyValuesToArray(
          uniqueDates,
          statusData.days,
          goToWeeks
        ),
      }
    },
    { negative: [], positive: [], invalid: [], noresult: [] }
  )

  const datasets = Object.entries(payload).reduce(
    (
      acc: Array<ConnectionOnCallByStatusDatasetItemType>,
      curr: [string, DatesResult]
    ): Array<ConnectionOnCallByStatusDatasetItemType> => {
      const [statusName, statusData] = curr
      const dataset: ConnectionOnCallByStatusDatasetItemType = {
        label: buildLabel(statusName, statusData),
        pointStyle: 'round' as const,
        data: statusesData[statusName],
        fill: true,
        ...connectOnCallByStatusConfig[statusName],
      }
      return [...acc, dataset]
    },
    []
  )
  const chartPayload = datasets.map(({ data }) => data)

  const stepSize =
    (Math.ceil(
      Math.max(
        ...chartPayload[0].map((chartPayloadItem, index) => {
          return (
            chartPayloadItem +
            chartPayload[1][index] +
            chartPayload[2][index] +
            chartPayload[3][index]
          )
        })
      ) / 10
    ) *
      10) /
    2

  return {
    stepSize,
    labels,
    datasets,
  }
}

export const connectOnCallTotalStatusSlice = createSlice({
  name: 'connectOnCallStatusResults',
  initialState,
  reducers: {
    setConnectOnCallStatusResults: (
      state,
      { payload }: PayloadAction<ConnectOnCallByStatusResult>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setConnectOnCallStatusResults } =
  connectOnCallTotalStatusSlice.actions

export const { reducer: connectOnCallStatusResults } =
  connectOnCallTotalStatusSlice

export const loginFormConfig = {
  username: {
    name: 'username',
    type: 'text',
    label: 'Login',
    placeholder: 'Enter login',
  },
  password: {
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Enter password',
  },
}

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Range } from 'react-date-range'
import { DropDown } from '../PlazusDropdown'
import { PlazusButton } from '../PlazusButton'
import { navBarTypes } from '../../constants/navBar'
import { accessTokenStorageKey } from '../../constants/common'
import { routes } from '../../routing'
import { PlazusModal } from '../PlazusModal'
import { DateRangeSelector } from '../DateRangeSelector'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getExportRange } from '../../redux/selectors'
import { setExportRange } from '../../redux/slices/exportRange'
import { exportInvalidTestResultPicturesData } from '../../redux/thunks/export'
import { formatDate } from '../../utils'
import './index.scss'

export function NavBar() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const exportRange = useAppSelector(getExportRange)

  function logOut() {
    sessionStorage.removeItem(accessTokenStorageKey)
    navigate(routes.login.path)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  function onCancelDateRangeClick() {
    closeModal()
  }

  function onApplyDateRangeClick({ startDate, endDate }: Range) {
    if (startDate && endDate) {
      dispatch(
        setExportRange({
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        })
      )
    }
  }

  function onExportClick() {
    const { startDate, endDate } = exportRange
    const payload = {
      body: { from: startDate, to: endDate },
      onSuccess: () => closeModal(),
      onError: () => closeModal(),
    }
    dispatch(exportInvalidTestResultPicturesData(payload))
  }

  function renderModalButtons() {
    const buttons = [
      {
        type: 'button' as const,
        text: 'Cancel',
        visualType: 'filled' as const,
        className: 'cancel-button',
        onClick: closeModal,
      },
      {
        type: 'button' as const,
        text: 'Export',
        visualType: 'filled' as const,
        className: 'export-button',
        onClick: onExportClick,
      },
    ]
    return (
      <div className="buttons-wrapper">
        {buttons.map((buttonProps, index) => (
          <PlazusButton key={index} {...buttonProps} />
        ))}
      </div>
    )
  }

  return (
    <>
      <nav className="d-flex app-navbar">
        <DropDown
          dropDownType={navBarTypes.timeGap}
          className="app-navbar__item"
        />
        <DropDown
          dropDownType={navBarTypes.export}
          className="app-navbar__item"
          openModal={() => {
            setIsModalOpen(true)
          }}
        />
        <PlazusButton
          text="Log out"
          visualType="outlined"
          type="button"
          className="app-navbar__item"
          onClick={logOut}
        />
      </nav>
      <PlazusModal
        isOpen={isModalOpen}
        title="Export pictures for invalid results"
        onClose={() => {
          setIsModalOpen(false)
        }}
        buttons={renderModalButtons}
      >
        <DateRangeSelector
          onCancel={onCancelDateRangeClick}
          onApply={onApplyDateRangeClick}
        />
      </PlazusModal>
    </>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TestsByProvinceDatasetItemType } from '../../../../types/charts'
import {
  KeyValuePercentPair,
  ParticipantTestsResults,
} from '../../../../types/api'

import { chartConfig } from '../../../../constants/charts'

import { getXAxisLabelsWithStackbar } from '../../../../utils'

const { chartColors, doubleBar } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<string>
    datasets: Array<TestsByProvinceDatasetItemType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function countTotalByProvince(raws: Array<KeyValuePercentPair>) {
  return raws.reduce(
    (acc: { [key: string]: number }, curr: KeyValuePercentPair) => {
      const existed = acc[curr.key] || 0
      const currentPercent = curr?.percent || 0
      return {
        ...acc,
        [curr.key]: existed + currentPercent,
      }
    },
    {}
  )
}

function normalizeData(data: ParticipantTestsResults) {
  let labels = getXAxisLabelsWithStackbar(data)

  const raws = Object.values(data)
  const provincesPercentsSum = countTotalByProvince(raws.flat())

  const countSumByProvinces = labels
    .map((label) => ({
      key: label,
      value: provincesPercentsSum[label],
    }))
    .reverse()

  labels = countSumByProvinces
    .sort((a, b) => b.value - a.value)
    .map((sumItem) => sumItem.key)

  const test1 = labels.map(
    (key) => data[1].find((n) => n.key === key)?.percent || 0
  )
  const test2 = labels.map(
    (key) => data[2].find((n) => n.key === key)?.percent || 0
  )
  const test3 = labels.map(
    (key) => data[3].find((n) => n.key === key)?.percent || 0
  )
  const test4 = labels.map(
    (key) => data['4+'].find((n) => n.key === key)?.percent || 0
  )

  const persentage = doubleBar

  return {
    labels,
    datasets: [
      {
        label: '1',
        data: test1,
        backgroundColor: chartColors.orange,
        ...persentage,
      },
      {
        label: '2',
        data: test2,
        backgroundColor: chartColors.blue,
        ...persentage,
      },
      {
        label: '3',
        data: test3,
        backgroundColor: chartColors.lightGreen,
        ...persentage,
      },
      {
        label: '4+',
        data: test4,
        backgroundColor: chartColors.yellow,
        ...persentage,
      },
    ],
  }
}

export const participantsTestsByProvinceSlice = createSlice({
  name: 'participantsTestsByProvince',
  initialState,
  reducers: {
    setParticipantsTestsByProvince: (
      state,
      { payload }: PayloadAction<ParticipantTestsResults>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setParticipantsTestsByProvince } =
  participantsTestsByProvinceSlice.actions

export const { reducer: participantsTestsByProvince } =
  participantsTestsByProvinceSlice

import React from 'react'
import { createPortal } from 'react-dom'
import { LoaderIndicator } from '../LoaderIndicator'

const loaderRoot = document.getElementById('loader') as Element

type PropsType = {
  show: boolean
}

export const LoaderPortal: React.FC<PropsType> = ({
  show = false,
}: PropsType) => {
  const loaderJSX = show ? <LoaderIndicator /> : null
  return createPortal(loaderJSX, loaderRoot)
}

import React from 'react'
import { Spinner } from 'react-bootstrap'
import './index.scss'

export function LoaderIndicator() {
  return (
    <div className="loader-backdrop d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}

import React from 'react'
import { ButtonTypes, ButtonVisualtypesTypes } from '../../types/common'
import { PlazusIcon } from '../PlazusIcon'
import './index.scss'

export type PlazusButtonPropsType = {
  type: ButtonTypes
  text: string
  visualType?: ButtonVisualtypesTypes
  iconPath?: string
  className?: string
  disabled?: boolean
  isWithArrow?: boolean
  onClick?: () => void
}

export function PlazusButton({
  visualType = 'outlined',
  text = '',
  type = 'button',
  iconPath,
  className = '',
  disabled = false,
  isWithArrow = false,
  onClick,
}: PlazusButtonPropsType) {
  const outliedButtonClassName =
    visualType === 'outlined' ? 'outlined-button' : ''

  const filledButtonClassName = visualType === 'filled' ? 'filled-button' : ''

  return (
    <button
      type={type}
      disabled={disabled}
      className={`plazus-button ${className} ${
        outliedButtonClassName || filledButtonClassName
      }`}
      onClick={onClick && onClick}
    >
      {iconPath ? <PlazusIcon iconPath={iconPath} /> : null}
      <span>{text}</span>
      {isWithArrow ? (
        <PlazusIcon
          iconPath={`${process.env.PUBLIC_URL}images/down-arrow.png`}
        />
      ) : null}
    </button>
  )
}

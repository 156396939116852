import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { ParticipantsResultsResponse } from '../../../../types/api'
import { getDatasetDataItem, getXAxisLabelsWithAge } from '../../../../utils'

const { chartColors } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<Array<string>>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: ParticipantsResultsResponse) {
  const labels = getXAxisLabelsWithAge()

  const negative = getDatasetDataItem(
    data.negative,
    labels,
    'Negative',
    chartColors.lightGreen
  )

  const total = getDatasetDataItem(
    data.total,
    labels,
    'Total',
    chartColors.orange
  )

  return {
    labels,
    datasets: [total, negative],
  }
}

export const participantsByAgeSlice = createSlice({
  name: 'negativePositiveInvalidByAge',
  initialState,
  reducers: {
    setNegativeByAge: (
      state,
      { payload }: PayloadAction<ParticipantsResultsResponse>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setNegativeByAge } = participantsByAgeSlice.actions

export const { reducer: participantsByAge } = participantsByAgeSlice

import React from 'react'
import { Form } from 'react-bootstrap'
import './index.scss'

type FormFieldPropsType = {
  name: string
  type: string
  value: string
  placeholder: string
  label: string
  error?: string
  touched?: boolean
  readOnly?: boolean
  onChange?: (event: React.ChangeEvent) => void
  onBlur?: (event: React.FocusEvent) => void
  onClick?: (event: React.MouseEvent) => void
}

export function FormField(props: FormFieldPropsType) {
  const {
    type,
    value,
    onChange,
    onBlur,
    label,
    placeholder,
    error,
    name,
    touched,
    readOnly,
    onClick,
  } = props
  return (
    <Form.Group className="form-field">
      <Form.Label className="form-field__label">{label}</Form.Label>
      <Form.Control
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
      />
      <Form.Control.Feedback
        type="invalid"
        className={`${error && touched ? 'form-field__error' : ''}`}
      >
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

import React from 'react'
import { Bar } from 'react-chartjs-2'
import { BarChartDataType, ChartDatasetType } from '../../../types/charts'
import { chartConfig } from '../../../constants/charts'
import { percentFilter } from '../../../utils'

const { chartColors, font } = chartConfig

type PropsType = {
  data: BarChartDataType
  legendTitle: string
  dalaLabelPercent?: boolean
  isWithAnnotation?: boolean
}

export function VerticalBarChart({
  data,
  legendTitle,
  dalaLabelPercent,
  isWithAnnotation,
}: PropsType) {
  function renderAnnotation() {
    if (!isWithAnnotation) return {}
    return Object.fromEntries(
      data.datasets.map((dataItem: ChartDatasetType) => {
        return [
          'line1',
          {
            type: 'line' as const,
            yMin: dataItem.data[0],
            yMax: dataItem.data[0],
            borderColor: chartColors.annotation,
            borderWidth: 1,
          },
        ]
      })
    )
  }

  const barValues = data.datasets
    .map((datasetItem) => datasetItem.data)
    .flat()
    .map((value) => Number(value))

  const maxValue = Math.max(...barValues) * 1.2 * (dalaLabelPercent ? 1.15 : 1)

  const options = {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: chartColors.grey,
        font: { family: font, size: 12, weight: 400 },
        anchor: 'end' as const,
        align: 'end' as const,
        offset: 5,
        rotation: 270,
        formatter: (value: number) => {
          if (dalaLabelPercent) {
            return percentFilter(value, { fractionDigits: 2 })
          }
          return value
        },
      },
      annotation: {
        annotations: renderAnnotation(),
      },
      legend: {
        align: 'center' as const,
        position: 'top' as const,
        maxHeight: 50,
        labels: {
          usePointStyle: true,
          color: chartColors.black,
          font: { family: font, size: 14 },
          boxWidth: 8,
          boxHeight: 8,
        },
      },
      title: {
        display: true,
        text: legendTitle,
        font: { family: font, size: 14 },
        color: chartColors.black,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      yAxis: {
        display: false,
        max: maxValue,
      },
      xAxis: {
        ticks: {
          font: { family: font, size: 14 },
          color: chartColors.black,
          padding: 11,
        },
        min: 0,
        max: 200,
        grid: {
          display: false,
        },
      },
    },
  }

  return <Bar height={300} options={options} data={data} />
}

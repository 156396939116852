import React from 'react'
import './index.scss'

type PropsType = {
  title: string
  mainText: string | number
  total?: string
  percent?: string
}

export function CardBox({ mainText, title, total, percent }: PropsType) {
  return (
    <div className="card-box d-flex flex-column align-items-center justify-content-center">
      <h4 className="card-box__title text-center">{title}</h4>
      <div className="card-box__content d-flex flex-column align-items-center justify-content-center">
        <p className="card-box__content--item">
          <span>{mainText}</span>
          {total ? <span> of {total}</span> : null}
        </p>

        {percent ? (
          <p className="card-box__content--item">
            <span>({percent})</span>
          </p>
        ) : null}
      </div>
    </div>
  )
}

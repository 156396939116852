import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectOnCallTotalResult } from '../../../types/api'

export type ConnectionOnCallTotalStateType = {
  data: ConnectOnCallTotalResult
}

const initialState: ConnectionOnCallTotalStateType = {
  data: {
    positive: {
      key: '',
      value: 0,
      percent: 0,
      itemKey: '',
    },
    negative: {
      key: '',
      value: 0,
      percent: 0,
      itemKey: '',
    },
    invalid: {
      key: '',
      value: 0,
      percent: 0,
      itemKey: '',
    },
    total: {
      key: '',
      value: 0,
      percent: 0,
      itemKey: '',
    },
  },
}

export const connectOnCallTotalResultsSlice = createSlice({
  name: 'connectOnCallTotalResults',
  initialState,
  reducers: {
    setConnectOnCallTotalResults: (
      state,
      { payload }: PayloadAction<ConnectOnCallTotalResult>
    ) => {
      state.data = payload
    },
  },
})

export const { setConnectOnCallTotalResults } =
  connectOnCallTotalResultsSlice.actions

export const { reducer: connectOnCallTotalResults } =
  connectOnCallTotalResultsSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { appByLanguageLabels, chartConfig } from '../../../constants/charts'
import { AppByLanguageResult, AppByLanguageStateType } from '../../../types/api'
import { percentFilter } from '../../../utils'

const { chartColors } = chartConfig

const initialState: AppByLanguageStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: AppByLanguageResult) {
  const { en, fr } = data
  return {
    labels: Object.values(appByLanguageLabels),
    datasets: [
      {
        label: '',
        backgroundColor: [chartColors.orange, chartColors.lightGreen],
        hoverBackgroundColor: [chartColors.orange, chartColors.lightGreen],
        data: [en, fr],
        labels: [
          `${percentFilter(en)}\nEnglish`,
          `${percentFilter(fr)}\nFrench`,
        ],
      },
    ],
  }
}

export const appByLanguageSlice = createSlice({
  name: 'appByLanguage',
  initialState,
  reducers: {
    setAppByLanguage: (
      state,
      { payload }: PayloadAction<AppByLanguageResult>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setAppByLanguage } = appByLanguageSlice.actions

export const { reducer: appByLanguage } = appByLanguageSlice

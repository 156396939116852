import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Context } from 'chartjs-plugin-datalabels'
import { DoughnutChartUnionType } from '../../../types/charts'
import { chartConfig } from '../../../constants/charts'

const { chartColors, font } = chartConfig

type PropsType<T> = {
  data: T
  legendTitle: string
}

export function DoughnutChart<T extends DoughnutChartUnionType>({
  data,
  legendTitle,
}: PropsType<T>) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: 0,
    animation: {
      duration: 0,
    },
    layout: {
      padding: { bottom: 40 },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: chartColors.black,
        font: { family: font, size: 14, weight: 500 },
        anchor: 'end' as const,
        align: 'end' as const,
        offset: 5,
        rotation: 0,
        formatter: (value: number, context: Context) => {
          const { dataIndex } = context
          // @ts-ignore
          const { labels } = context.dataset
          return labels[dataIndex]
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        font: { family: font, size: 14 },
        color: chartColors.black,
        position: 'top' as const,
        padding: 55,
        text: legendTitle,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      yAxis: {
        display: false,
      },
      xAxis: {
        display: false,
      },
    },
  }
  return <Doughnut height={300} data={data} options={options} />
}

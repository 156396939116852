import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { add, startOfMonth, endOfMonth } from 'date-fns'
import { formatDate } from '../../../utils'

type ExportRangeDataType = { startDate: string; endDate: string }
type ExportRangeStateType = {
  data: ExportRangeDataType
}

const dateInstance = new Date()

const initialState: ExportRangeStateType = {
  data: {
    startDate: formatDate(startOfMonth(add(dateInstance, { months: -1 }))),
    endDate: formatDate(endOfMonth(add(dateInstance, { months: -1 }))),
  },
}

function normalizeData({ startDate, endDate }: ExportRangeDataType) {
  if (startDate && endDate) {
    return { startDate, endDate }
  }
  return initialState.data
}

export const exportRangeSlice = createSlice({
  name: 'exportRange',
  initialState,
  reducers: {
    setExportRange: (
      state,
      { payload }: PayloadAction<ExportRangeDataType>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setExportRange } = exportRangeSlice.actions

export const { reducer: exportRange } = exportRangeSlice

import { BrowserHistory } from 'history'
import React, { useState, useLayoutEffect } from 'react'
import { Routes, Route, Navigate, Router } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { ProtectedPage } from '../components/ProtectedPage'
import { DashboardPage } from '../pages/DashboardPage'

import { LoginPage } from '../pages/LoginPage'
import { history } from '../services/history'

export const routes = {
  dashboard: {
    path: '/',
    isProtected: true,
    isWithHeader: true,
    Element: DashboardPage,
  },
  login: {
    path: 'login-form',
    isProtected: false,
    isWithHeader: false,
    Element: LoginPage,
  },
}

type GetRouteLayoutPropsType = {
  Element: React.FC
  isProtected: boolean
  isWithHeader: boolean
}

function getRouteLayout({
  Element,
  isProtected,
  isWithHeader,
}: GetRouteLayoutPropsType): React.ReactElement {
  return (
    <Layout isWithHeader={isWithHeader}>
      {isProtected ? <ProtectedPage element={Element} /> : <Element />}
    </Layout>
  )
}

type CustomRouterPropsType = {
  appHistory: BrowserHistory
  children: React.ReactNode
}

const CustomRouter = ({ appHistory, children }: CustomRouterPropsType) => {
  const [state, setState] = useState({
    action: appHistory.action,
    location: appHistory.location,
  })

  useLayoutEffect(() => appHistory.listen(setState), [appHistory])

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={appHistory}
    >
      {children}
    </Router>
  )
}

export function AppRouter() {
  return (
    <CustomRouter appHistory={history}>
      <Routes>
        {Object.entries(routes).map(
          ([key, { path, Element, isProtected, isWithHeader }]) => {
            return (
              <Route
                key={key}
                path={path}
                element={getRouteLayout({ Element, isProtected, isWithHeader })}
              />
            )
          }
        )}
        <Route
          path="*"
          element={<Navigate replace to={routes.dashboard.path} />}
        />
      </Routes>
    </CustomRouter>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ToasterType = {
  id: string
  header: string
  text: string
  type: 'danger'
  position: 'top-end' | 'bottom-end'
}

type ToasterStateType = {
  toasters: Array<ToasterType>
}

const initialState: ToasterStateType = {
  toasters: [],
}

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToaster: (state, { payload }: PayloadAction<ToasterType>) => {
      state.toasters = [...state.toasters, payload]
    },
    deleteToasterById: (state, { payload }: PayloadAction<string>) => {
      state.toasters = state.toasters.filter(
        (toaster) => toaster.id !== payload
      )
    },
  },
})

export const { addToaster, deleteToasterById } = toasterSlice.actions

export const { reducer: toaster } = toasterSlice

import { timeGaps } from './common'
import { PlazusButtonPropsType } from '../components/PlazusButton/index'

export type DropDownTypesType = {
  timeGap: 'timeGap'
  export: 'export'
}

type ListItemType = { label: string; timeGap?: number; action?: string }

type NavBarConfigType = {
  [key: string]: {
    setSelectedLabel: boolean
    toggler: PlazusButtonPropsType
    listItems: Array<ListItemType>
  }
}

export const navBarTypes: DropDownTypesType = {
  timeGap: 'timeGap',
  export: 'export',
}

export const navBarConfigs: NavBarConfigType = {
  [navBarTypes.timeGap]: {
    setSelectedLabel: true,
    toggler: {
      text: 'Current',
      visualType: 'outlined',
      isWithArrow: true,
      type: 'button',
    },
    listItems: [
      {
        label: 'Current',
        timeGap: timeGaps.current,
      },
      {
        label: 'Recent Week',
        timeGap: timeGaps.recentWeek,
      },
      {
        label: 'Recent Month',
        timeGap: timeGaps.recentMonth,
      },
    ],
  },
  [navBarTypes.export]: {
    setSelectedLabel: false,
    toggler: {
      text: 'Export Data',
      visualType: 'filled',
      type: 'button',
      isWithArrow: true,
      iconPath: 'images/file.png',
    },
    listItems: [
      {
        label: 'Export Data',
        action: 'defaultExport',
      },
      {
        label: 'Export pictures for invalid results',
        action: 'openModal',
      },
    ],
  },
}

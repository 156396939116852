import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getPositivityRateByAge,
  getPositivityRateByProvince,
  getTotalInvalidNegativePositiveResults,
} from '../../../redux/selectors'
import { getBarChartStyle } from '../../../utils/styleUtils'
import { VerticalBarChart } from '../../charts/VerticalBarChart'
import { Note } from '../../Note'
import { SectionHeader } from '../../SectionHeader'

export function PositivityRate() {
  const { negative, positive, invalid } = useAppSelector(
    getTotalInvalidNegativePositiveResults
  )

  const chartDataByProvince = useAppSelector(getPositivityRateByProvince)
  const chartDataByAge = useAppSelector(getPositivityRateByAge)

  return (
    <Container fluid={true} className="graph-container">
      <SectionHeader
        title="Positivity Rate"
        positive={positive}
        total={positive.total + negative.total + invalid.total}
      />
      <Row className="justify-content-center">
        <Col
          md={12}
          lg={10}
          xl={7}
          xxl={7}
          style={getBarChartStyle(chartDataByProvince.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByProvince}
            legendTitle="Positivity Rate by Province, %"
            dalaLabelPercent={true}
            isWithAnnotation={true}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Note
            align="center"
            text="Note: calculated the positivity rate by dividing the number of positive test results by regional divided by the total number of test results submitted for that region"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          md={10}
          lg={6}
          xl={3}
          xxl={3}
          style={getBarChartStyle(chartDataByAge.labels.length)}
        >
          <VerticalBarChart
            data={chartDataByAge}
            legendTitle="Positivity Rate by Age, %"
            dalaLabelPercent={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Note
            align="center"
            text="Note: calculated the positivity rate by dividing the number of positive test results by aged group divided by the total number of test results submitted for that age group"
          />
        </Col>
      </Row>
    </Container>
  )
}

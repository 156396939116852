import React, { useRef, useMemo } from 'react'
import {
  ChartDataset,
  ChartTypeRegistry,
  ScatterDataPoint,
  BubbleDataPoint,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Context } from 'chartjs-plugin-datalabels'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { chartConfig } from '../../../constants/charts'
import { numberFilter, percentFilter } from '../../../utils'
import { UsersFunelResultChartType } from '../../../types/api'

const { chartColors, font } = chartConfig

type PropsType = {
  data: UsersFunelResultChartType
  legendTitle?: string
  dalaLabelValueVsPercent?: boolean
}

type ExtendableTooltipContextType = ChartDataset<
  keyof ChartTypeRegistry,
  (number | ScatterDataPoint | BubbleDataPoint | null)[]
> & {
  rawData: Array<{
    key: string
    percent1: number
    percent2: number
    value: number
  }>
}

export function FunnelChart({ data, legendTitle }: PropsType) {
  const chartRef = useRef<ChartJSOrUndefined<'bar', number[], string> | null>()

  const maxValue = useMemo(() => {
    const chartWidth = chartRef?.current?.width || 0
    return data.maxValue * (1 + 90 / (chartWidth / 2))
  }, [data])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    animation: {
      duration: 0,
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            anchor: 'start' as const,
            align: 'right' as const,
            clamp: true,
            font: { family: font, size: 14 },
            color: chartColors.black,
            offset: -4,
            textAlign: 'left' as const,
            rotation: 0,
            formatter: (value: number, context: Context) => {
              const originalValue = (
                context.dataset as ExtendableTooltipContextType
              ).rawData[context.dataIndex]
              if (originalValue.key !== undefined) {
                return `${originalValue.key} (${numberFilter(
                  originalValue.value
                )})\n\n`
              }
              return ''
            },
          },
          value: {
            color: '#666666',
            font: { family: font, size: 12 },
            anchor: 'end' as const,
            align: 'end' as const,
            offset: 5,
            rotation: 0,
            formatter: (value: number, context: Context) => {
              const originalValue = (
                context.dataset as ExtendableTooltipContextType
              ).rawData[context.dataIndex]
              if (originalValue.percent2 !== undefined) {
                return `${percentFilter(
                  originalValue.percent2
                )} (${percentFilter(value)})`
              }
              return ''
            },
          },
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        position: 'top' as const,
        text: legendTitle,
        font: { family: font, size: legendTitle ? 14 : 0 },
        color: chartColors.black,
      },
    },
    scales: {
      yAxis: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      xAxis: {
        display: false,
        max: maxValue,
        ticks: {
          display: false,
        },
      },
    },
  }

  return <Bar ref={chartRef} options={options} data={data} />
}

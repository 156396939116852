export const axiosRequestTimeout = 12000
export const accessTokenStorageKey = 'ACCESS_TOKEN_STORRAGE_KEY'

type ToasterPositionTypes = {
  topEnd: 'top-end'
  bottomEnd: 'bottom-end'
}

export const toasterPositions: ToasterPositionTypes = {
  topEnd: 'top-end',
  bottomEnd: 'bottom-end',
}

type ToasterTypes = {
  danger: 'danger'
}

export const toasterTypes: ToasterTypes = {
  danger: 'danger',
}

export const ageGroups = ['<20', '20-29', '30-39', '40-49', '>=50']

export const timeGaps = {
  current: 0,
  recentWeek: 1,
  recentMonth: 2,
}

// do not change an order in the object below
export const surveysTypesLabels = {
  preTestPrimary: 'Pre Test Survey',
  postTestSurvey: 'Post Test Survey',
  followUp1m: '1 month',
  followUp3m: '3 months',
  followUp6m: '6 months',
  preTestSecondary: 'Optional Pre Test Survey',
}

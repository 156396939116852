import { createAsyncThunk } from '@reduxjs/toolkit'
import { toasterTypes, toasterPositions } from '../../constants/common'
import {
  getExportData,
  getExportInvalidTestResultPicturesData,
} from '../../services/exportApi'
import { catchAuthError } from '../../utils'
import { addLoaderAction, deleteLoaderAction } from '../slices/loader'
import { addToaster } from '../slices/toasterSlice'
import { FromToThunkPayloadType } from './dashboard'

export const defaultExport = createAsyncThunk<void>(
  'dashboard/defaultExport',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('defaultExport'))
    const anchor = document.createElement('a')
    try {
      const data = await getExportData()
      document.body.appendChild(anchor)
      const fileName = 'ExportedData.xls'
      const blob = new Blob([data])
      const objectUrl = window.URL.createObjectURL(blob)
      anchor.href = objectUrl
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(objectUrl)
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('defaultExport'))
      anchor.remove()
    }
  }
)

export const exportInvalidTestResultPicturesData = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/exportInvalidTestResultPicturesData',
  async ({ body, onError, onSuccess }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('exportInvalidTestResultPicturesData'))
    const anchor = document.createElement('a')
    try {
      const data = await getExportInvalidTestResultPicturesData(body)
      document.body.appendChild(anchor)
      const dates =
        body?.from === body?.to ? body?.from || '' : `${body?.from}_${body?.to}`
      const fileName = `${dates.replace(/-/g, '')}_Invalid.zip`
      const blob = new Blob([data])
      const objectUrl = window.URL.createObjectURL(blob)
      anchor.href = objectUrl
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(objectUrl)
      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
      thunkAPI.dispatch(
        addToaster({
          id: Date.now().toString(),
          type: toasterTypes.danger,
          position: toasterPositions.bottomEnd,
          text: 'Error while exporting pictures for invalid results',
          header: '',
        })
      )
    } finally {
      anchor.remove()
      thunkAPI.dispatch(
        deleteLoaderAction('exportInvalidTestResultPicturesData')
      )
    }
  }
)

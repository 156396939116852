import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { surveysTypesLabels } from '../../../constants/common'
import {
  KeyValuePercentPair,
  QuestionareByTypeResultStateType,
} from '../../../types/api'
import { chartConfig } from '../../../constants/charts'

const { chartColors, questionareResult } = chartConfig

const initialState: QuestionareByTypeResultStateType = {
  data: {
    hasDatByType: false,
    labels: [],
    datasets: [],
  },
}

function normalizeData(payload: Array<KeyValuePercentPair>) {
  const labels = Object.values(surveysTypesLabels)

  const transformedData = Object.fromEntries(
    payload.map((dataItem) => [dataItem.key, dataItem])
  )

  const hasDatByType = !!payload.length

  type AccType = {
    data: Array<number>
    raw: Array<{ value: number; percent: number }>
  }

  const { data, raw } = Object.keys(surveysTypesLabels).reduce(
    (acc: AccType, curr: string): AccType => {
      const dataItem = transformedData[curr]
      if (!dataItem) return acc
      return {
        data: [...acc.data, dataItem.value],
        raw: [
          ...acc.raw,
          { value: dataItem.value, percent: dataItem.percent || 0 },
        ],
      }
    },
    { data: [], raw: [] }
  )

  return {
    labels,
    hasDatByType,
    datasets: [
      {
        data,
        raw,
        backgroundColor: chartColors.orange,
        hoverBackgroundColor: chartColors.orange,
        ...questionareResult,
      },
    ],
  }
}

export const questionnaireByTypeResultsSlice = createSlice({
  name: 'questionnaireByTypeResults',
  initialState,
  reducers: {
    setQuestionnaireByTypeResults: (
      state,
      { payload }: PayloadAction<Array<KeyValuePercentPair>>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setQuestionnaireByTypeResults } =
  questionnaireByTypeResultsSlice.actions

export const { reducer: questionnaireByTypeResults } =
  questionnaireByTypeResultsSlice

import axios from 'axios'
import { routes } from '../routing'
import { history } from './history'
import { setAccessTokenToRequestHeader } from './interceptors'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

axiosInstance.interceptors.request.use(setAccessTokenToRequestHeader)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      sessionStorage.clear()
      history.replace(routes.login.path)
    }
    return Promise.reject(error)
  }
)

export { axiosInstance }

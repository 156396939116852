export const chartConfig = {
  chartColors: {
    white: '#ffffff',
    orange: '#f79263',
    lightGreen: '#98cb36',
    blue: '#2575b2',
    yellow: '#f7d763',
    black: '#000000',
    grey: '#666666',
    annotation: '#b1b1b1',
    greyTooltip: '#999999',
    transparent: 'transparent',
  },
  font: 'SF-Pro-Display',
  singleBar: {
    categoryPercentage: 0.6,
    barPercentage: 0.7,
  },
  doubleBar: {
    categoryPercentage: 0.41,
    barPercentage: 0.44,
  },
  funnelChart: {
    categoryPercentage: 0.6,
    barPercentage: 0.7,
  },
  questionareResult: {
    categoryPercentage: 0.5,
    barPercentage: 0.9,
  },
  tension: 0.5,
  pointRadius: 0,
}

export const xAxisWithAgeLables = ['years', 'of age']

export const appByLanguageLabels = {
  en: 'English',
  fr: 'French',
}

type ConnectOnCallByStatusConfigType = {
  [key: string]: {
    borderColor: string
    backgroundColor: string
    order: number
    pointRadius: number
    pointHoverBackgroundColor: string
    pointHoverBorderColor: string
    tension: number
  }
}

export const connectOnCallByStatusConfig: ConnectOnCallByStatusConfigType = {
  positive: {
    borderColor: chartConfig.chartColors.orange,
    backgroundColor: chartConfig.chartColors.orange,
    order: 3,
    pointRadius: chartConfig.pointRadius,
    tension: chartConfig.tension,
    pointHoverBackgroundColor: chartConfig.chartColors.transparent,
    pointHoverBorderColor: chartConfig.chartColors.transparent,
  },
  negative: {
    borderColor: chartConfig.chartColors.yellow,
    backgroundColor: chartConfig.chartColors.yellow,
    order: 2,
    pointRadius: chartConfig.pointRadius,
    tension: chartConfig.tension,
    pointHoverBackgroundColor: chartConfig.chartColors.transparent,
    pointHoverBorderColor: chartConfig.chartColors.transparent,
  },
  invalid: {
    borderColor: chartConfig.chartColors.lightGreen,
    backgroundColor: chartConfig.chartColors.lightGreen,
    order: 1,
    pointRadius: chartConfig.pointRadius,
    tension: chartConfig.tension,
    pointHoverBackgroundColor: chartConfig.chartColors.transparent,
    pointHoverBorderColor: chartConfig.chartColors.transparent,
  },
  noresult: {
    borderColor: chartConfig.chartColors.blue,
    backgroundColor: chartConfig.chartColors.blue,
    order: 0,
    pointRadius: chartConfig.pointRadius,
    tension: chartConfig.tension,
    pointHoverBackgroundColor: chartConfig.chartColors.transparent,
    pointHoverBorderColor: chartConfig.chartColors.transparent,
  },
}

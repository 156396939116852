import { RootState } from './store'

export const getToasters = ({ toaster }: RootState) => toaster.toasters

export const getTotalInvalidNegativePositiveResults = ({ total }: RootState) =>
  total.totalPositiveAndInvalidResults

export const getNegativeParticipantsByProvince = ({
  participantsByProvince,
}: RootState) => participantsByProvince.data

export const getNegativeParticipantsByAge = ({
  participantsByAge,
}: RootState) => participantsByAge.data

export const getPositiveVsInvalidByProvince = ({
  positiveVsInvalidByProvince,
}: RootState) => positiveVsInvalidByProvince.data

export const getPositiveVsInvalidByAge = ({
  positiveVsInvalidByAge,
}: RootState) => positiveVsInvalidByAge.data

export const getPositivityRateByProvince = ({
  positivityRateByProvince,
}: RootState) => positivityRateByProvince.data

export const getPositivityRateByAge = ({ positivityRateByAge }: RootState) =>
  positivityRateByAge.data

export const getParticipantsTestsByProvince = ({
  participantsTestsByProvince,
}: RootState) => participantsTestsByProvince.data

export const getTimeGap = ({ timeGapFilter }: RootState) =>
  timeGapFilter.data.timeGap

export const getTimeGapDates = ({ timeGapFilter }: RootState) =>
  timeGapFilter.data

export const getAppOpened = ({ appOpened }: RootState) => appOpened.data

export const getUsersResultsByAge = ({ usersResultByAge }: RootState) =>
  usersResultByAge.data

export const getUsersResultsByProvince = ({
  usersResultByProvince,
}: RootState) => usersResultByProvince.data

export const getAppByLanguage = ({ appByLanguage }: RootState) =>
  appByLanguage.data

export const getDeactivatedApp = ({ deactivatedApp }: RootState) =>
  deactivatedApp.data

export const getUsersFunelResults = ({ usersFunelResults }: RootState) =>
  usersFunelResults.data

export const getQuestionnaireByTypeResults = ({
  questionnaireByTypeResults,
}: RootState) => {
  return questionnaireByTypeResults.data
}

export const getQuestionnaireByTimeResults = ({
  questionnaireOverTimeResults,
}: RootState) => {
  return questionnaireOverTimeResults.data
}

export const getConnectOnCallTotalResults = ({
  connectOnCallTotalResults,
}: RootState) => {
  return connectOnCallTotalResults.data
}

export const getConnectOnCallStatusResults = ({
  connectOnCallStatusResults,
}: RootState) => {
  return connectOnCallStatusResults.data
}

export const getExportRange = ({ exportRange }: RootState) => {
  return exportRange.data
}

export const isLoading = ({ loader }: RootState) => {
  return !!Object.keys(loader.actions).length
}

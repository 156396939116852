import React from 'react'
import { SectionsContainer } from '../../components/sections/SectionsContainer'
import './index.scss'

export function DashboardPage() {
  return (
    <div className="dashboard-container">
      <SectionsContainer />
    </div>
  )
}

import { object } from 'yup'
import { getSchemas } from './schemas/getSchemas'

type PropsType = {
  requiredFields: Array<string>
}

export function useValidation({ requiredFields }: PropsType) {
  const schemas = getSchemas(requiredFields)
  const validationSchema = object().shape(schemas)
  return { validationSchema }
}

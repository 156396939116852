import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { chartConfig } from '../../../../constants/charts'
import { ageGroups } from '../../../../constants/common'

import {
  KeyValuePercentPair,
  UsersResultByAgeStateType,
} from '../../../../types/api'

const { chartColors, singleBar } = chartConfig

const initialState: UsersResultByAgeStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(payload: Array<KeyValuePercentPair>) {
  const labels = ageGroups

  const data = labels.map(
    (label) => payload.find((dataItem) => dataItem.key === label)?.value || 0
  )

  const raw = labels.map(
    (label) =>
      payload.find((dataItem) => dataItem.key === label) || {
        key: label,
        value: 0,
        percent: 0,
      }
  )

  return {
    labels,
    datasets: [
      {
        data,
        raw,
        backgroundColor: chartColors.orange,
        hoverBackgroundColor: chartColors.orange,
        categoryPercentage: singleBar.categoryPercentage,
        barPercentage: singleBar.barPercentage,
      },
    ],
  }
}

export const usersResultByAgeSlice = createSlice({
  name: 'usersResultByAge',
  initialState,
  reducers: {
    setUsersResultByAge: (
      state,
      { payload }: PayloadAction<Array<KeyValuePercentPair>>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setUsersResultByAge } = usersResultByAgeSlice.actions

export const { reducer: usersResultByAge } = usersResultByAgeSlice

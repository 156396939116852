import { AxiosError } from 'axios'
import { format, max, min, parse } from 'date-fns'
import { Dispatch } from 'redux'
import {
  DateValuePair,
  ParticipantTestsResults,
  KeyValuePercentPair,
  KeyValuePair,
} from '../types/api'
import { routes } from '../routing/index'

import { xAxisWithAgeLables } from '../constants/charts'
import { ageGroups, accessTokenStorageKey } from '../constants/common'
import { ChartDatasetType } from '../types/charts'

import { FromToBodyType } from '../types/common'
import { history } from '../services/history'
import { setTimeGap } from '../redux/slices/timeGapFilter'

export function catchAuthError(e: unknown, dispatch: Dispatch) {
  const error = e as AxiosError
  if (error.response?.status === 401) {
    dispatch(setTimeGap(0))
    sessionStorage.clear()
    history.replace(routes.login.path)
  }
}

export function concatParams(body?: FromToBodyType) {
  let params = ''
  if (body) {
    const { from, to } = body
    params = from ? `/${from}/${to}` : ''
  }
  return params
}

export function getAccessToken() {
  return sessionStorage.getItem(accessTokenStorageKey) || ''
}

export function getXAxisLabels(
  data1?: Array<KeyValuePair>,
  data2?: Array<KeyValuePair>
): Array<string> {
  const keys1 = data1 ? data1.map(({ key }) => key) : []
  const keys2 = data2 ? data2.map(({ key }) => key) : []
  return Array.from(new Set([...keys1, ...keys2]))
}

export function getXAxisLabelsWithAge(): Array<Array<string>> {
  return ageGroups.map((age) => [age, ...xAxisWithAgeLables])
}

export function getXAxisLabelsWithStackbar(
  data: ParticipantTestsResults
): Array<string> {
  const keys = Object.values(data)
    .flat()
    .map(({ key }) => key)
  return Array.from(new Set(keys))
}

function getDatasetDataItemValue(
  isPercentAsValue?: boolean,
  dataItem?: KeyValuePercentPair
) {
  if (dataItem) {
    return isPercentAsValue && dataItem.percent
      ? dataItem.percent
      : dataItem.value
  }
  return 0
}

export function getDatasetDataItem(
  dataItem: Array<KeyValuePercentPair>,
  labels: Array<string> | Array<Array<string>>,
  label: string,
  barColor: string,
  isPercentAsValue?: boolean
): ChartDatasetType {
  const revertedDataItemByKeys = dataItem.reduce(
    (
      acc: { [key: string]: KeyValuePercentPair },
      curr: KeyValuePercentPair
    ) => {
      acc[curr.key] = curr
      return acc
    },
    {}
  )
  const data = labels.map((labelItem) => {
    if (!Array.isArray(labelItem)) {
      const existedKeyValuePair = revertedDataItemByKeys[labelItem]
      return getDatasetDataItemValue(isPercentAsValue, existedKeyValuePair)
    }
    // needed for charts that has x axis ticks by 2 or more lines
    const existedKeyValuePair = revertedDataItemByKeys[labelItem[0]]
    return getDatasetDataItemValue(isPercentAsValue, existedKeyValuePair)
  })
  return {
    data,
    label,
    backgroundColor: barColor,
    categoryPercentage: 0.41,
    barPercentage: 0.9,
  }
}

export function percentFilter(
  value: number,
  params?: { hideZeroValue?: boolean; fractionDigits?: number }
) {
  if (params?.hideZeroValue && value === 0) return ''

  return Number(value / 100).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: params?.fractionDigits || 0,
  })
}

export function mergeDates(days: Array<DateValuePair>) {
  return days
    .map((day) => parse(day.date, 'yyyy.MM.dd', new Date()))
    .sort((a: Date, b: Date) => {
      return a.getTime() - b.getTime()
    })
}

export function goToWeeksDates(allDates: Array<Date>) {
  return allDates.filter((_, index) => index % 7 === 0)
}

export function fillDatesSpaces(dates: Array<Date>) {
  const start = min(dates) as Date
  const end = max(dates) as Date

  const result: Date[] = []
  let current = start
  while (current <= end) {
    result.push(current)
    current = new Date(current)
    current.setDate(current.getDate() + 1)
  }

  return result
}

export function getLabelsForChart(
  dateValues: Date[],
  goToWeeks = false
): Array<Array<string> | number> {
  let dates = [...dateValues]
  if (goToWeeks) {
    dates = goToWeeksDates(dates)
  }
  const prevMonth = -1

  return dates.map((date: Date) => {
    const currentDate = date.getDate()
    const currentMonth = date.getMonth()
    if (currentMonth !== prevMonth) {
      return [format(date, 'dd'), format(date, 'MMM')]
    }
    return currentDate
  })
}

export function goToWeeksValues(values: number[]) {
  const temp = [...values]
  const result = []
  while (temp.length) {
    const part = temp.splice(0, 7)
    const sum = part.reduce((acc, curr) => acc + curr, 0)
    result.push(sum)
  }
  return result
}

export function addEmptyValuesToArray(
  allDates: Date[],
  values: DateValuePair[],
  goToWeeks = false
): Array<number> {
  let result = allDates.map(
    (d) => values.find((v) => v.date === format(d, 'yyyy.MM.dd'))?.value || 0
  )

  if (goToWeeks) {
    result = goToWeeksValues(result)
  }

  return result
}

const formatter = new Intl.NumberFormat()

export function numberFilter(value: number) {
  return formatter.format(Math.round(value))
}

export const formatDate = (date: Date, formatVarian = 'yyyy-MM-dd') =>
  format(date, formatVarian)

import React from 'react'
import { Container } from 'react-bootstrap'
import { NavBar } from '../NavBar'
import { PlazusIcon } from '../PlazusIcon'
import './index.scss'

export function PlazusHeader() {
  return (
    <Container fluid={true}>
      <header className="app-header d-flex justify-content-between align-items-center">
        <a href="#" className="navbar-brand">
          <PlazusIcon iconPath="images/logo.png" />
        </a>
        <NavBar />
      </header>
    </Container>
  )
}

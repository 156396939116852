import React from 'react'
import { PersentTotalPair } from '../../types/api'
import { numberFilter } from '../../utils'
import './index.scss'

type PropsType = {
  title: string
  total: number
  negative?: PersentTotalPair
  positive?: PersentTotalPair
  invalid?: PersentTotalPair
}

export function SectionHeader(props: PropsType) {
  const { title, total, negative, positive, invalid } = props

  function renderNegativeBlock() {
    return (
      <div className="total-item">
        <span className="total-label">Negative: </span>
        <span className="total-value">
          {numberFilter(negative?.total || 0)} (
          {Math.round(negative?.percent || 0)}
          %)
        </span>
      </div>
    )
  }

  function renderPositiveBlock() {
    return (
      <div className="total-item">
        <span className="total-label">Positive: </span>
        <span className="total-value">
          {numberFilter(positive?.total || 0)} (
          {Math.round(positive?.percent || 0)}%)
        </span>
      </div>
    )
  }

  function renderPositivaAndInvalidBlock() {
    return (
      <>
        <div className="total-item">
          <span className="total-label">Positive: </span>
          <span className="total-value">
            {numberFilter(positive?.total || 0)} (
            {Math.round(positive?.percent || 0)}%)
          </span>
        </div>
        <div className="total-item">
          <span className="total-label">Invalid: </span>
          <span className="total-value">
            {numberFilter(invalid?.total || 0)} (
            {Math.round(invalid?.percent || 0)}%)
          </span>
        </div>
      </>
    )
  }

  return (
    <div className="section-header">
      <h4 className="section-header__title text-center">{title}</h4>
      <div className="section-header__row d-flex justify-content-center">
        <div className="section-header__row--item d-flex">
          {negative && renderNegativeBlock()}
          {positive && !invalid && renderPositiveBlock()}
          {positive && invalid && renderPositivaAndInvalidBlock()}
          <div className="total-item">
            <span className="total-label">Total: </span>
            <span className="total-value">{numberFilter(total)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getConnectOnCallStatusResults,
  getConnectOnCallTotalResults,
} from '../../../redux/selectors'
import { KeyValuePercentPair } from '../../../types/api'
import { percentFilter } from '../../../utils'
import { CardBox } from '../../CardBox'
import { LineChart } from '../../charts/LineChart'

const cardBoxTitles: { [key: string]: string } = {
  positive: 'Average # of clicks by users with Positive results',
  negative: 'Average # of clicks by users with Negative results',
  invalid: 'Average # of clicks by users with Invalid results',
  total: 'Total # of users clicked on “Connect”',
}

export function ConnectOnCall() {
  const connectOnCallTotalResults = useAppSelector(getConnectOnCallTotalResults)

  const connectOnCallStatusResults = useAppSelector(
    getConnectOnCallStatusResults
  )

  return (
    <>
      <Container fluid={true} className="graph-container">
        <Row>
          <Col>
            <LineChart
              data={connectOnCallStatusResults}
              legendTitle="“Connect” to OnCall by Status"
              stepSize={connectOnCallStatusResults.stepSize}
              height={270}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center pb-5">
          {Object.entries(connectOnCallTotalResults).map(
            ([key, data]: [string, KeyValuePercentPair]) => {
              return (
                <Col
                  key={key}
                  md={6}
                  lg={6}
                  xl={3}
                  xxl={3}
                  className=" d-flex align-items-center justify-content-center"
                >
                  <CardBox
                    mainText={
                      Number.isInteger(data.value)
                        ? data.value
                        : data.value.toFixed(1)
                    }
                    title={cardBoxTitles[key]}
                    percent={
                      data.percent ? percentFilter(data.percent) : undefined
                    }
                  />
                </Col>
              )
            }
          )}
        </Row>
      </Container>
    </>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DatesResult, QuestionnaireOverTimeStateType } from '../../../types/api'

import { chartConfig } from '../../../constants/charts'
import {
  addEmptyValuesToArray,
  fillDatesSpaces,
  getLabelsForChart,
  mergeDates,
  numberFilter,
} from '../../../utils'

const { chartColors, tension, pointRadius } = chartConfig

const initialState: QuestionnaireOverTimeStateType = {
  data: {
    hasDataByTime: false,
    labels: [],
    datasets: [],
  },
}

function normalizeData(payload: DatesResult) {
  const goToWeeks = true

  const hasDataByTime = !!payload.days.length

  const merged = mergeDates(payload.days)
  const allDates = fillDatesSpaces(merged)
  const labels = getLabelsForChart(allDates, goToWeeks)

  const datasets = {
    label: numberFilter(payload.total),
    pointStyle: 'circle' as const,
    borderColor: chartColors.orange,
    pointRadius,
    pointHoverBackgroundColor: chartColors.transparent,
    pointHoverBorderColor: chartColors.transparent,
    fill: true,
    backgroundColor: chartColors.orange,
    data: addEmptyValuesToArray(allDates, payload.days || [], goToWeeks),
    tension,
  }

  return {
    labels,
    hasDataByTime,
    datasets: [datasets],
  }
}

export const questionnaireOverTimeResultsSlice = createSlice({
  name: 'questionnaireOverTimeResults',
  initialState,
  reducers: {
    setQuestionnaireOverTimeResults: (
      state,
      { payload }: PayloadAction<DatesResult>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setQuestionnaireOverTimeResults } =
  questionnaireOverTimeResultsSlice.actions

export const { reducer: questionnaireOverTimeResults } =
  questionnaireOverTimeResultsSlice

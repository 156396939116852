import React from 'react'
import Modal from 'react-bootstrap/Modal'

type PropsType = {
  isOpen: boolean
  title: string
  children: React.ReactNode
  buttons?: () => React.ReactNode
  onClose: () => void
}

export function PlazusModal({
  isOpen,
  title,
  buttons,
  children,
  onClose,
}: PropsType) {
  return (
    <Modal show={isOpen} onHide={onClose} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {buttons ? <Modal.Footer>{buttons()}</Modal.Footer> : null}
    </Modal>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { chartConfig } from '../../../constants/charts'
import { percentFilter } from '../../../utils'
import {
  KeyValueDoublePercentPair,
  UsersFunelResultStateType,
} from '../../../types/api'

const { chartColors, funnelChart } = chartConfig

const initialState: UsersFunelResultStateType = {
  data: {
    chart1: {
      labels: [],
      maxValue: 0,
      datasets: [],
    },
    chart2: {
      labels: [],
      maxValue: 0,
      datasets: [],
    },
  },
}

function normalizeData(data: Array<KeyValueDoublePercentPair>) {
  const funnelData = data.map((dataItem) => dataItem.percent1 || 0)

  const maxValue = Math.max(...funnelData) || 100

  const chart1 = {
    labels: funnelData.slice(0, 3).map((v) => percentFilter(v)),
    maxValue,
    datasets: [
      {
        label: '',
        backgroundColor: chartColors.orange,
        hoverBackgroundColor: chartColors.orange,
        data: funnelData.slice(0, 3),
        rawData: data.slice(0, 3),
        ...funnelChart,
      },
    ],
  }

  const chart2 = {
    labels: funnelData.slice(3, 6).map((v) => percentFilter(v)),
    maxValue,
    datasets: [
      {
        label: '',
        backgroundColor: chartColors.orange,
        hoverBackgroundColor: chartColors.orange,
        data: funnelData.slice(3, 6),
        rawData: data.slice(3, 6),
        ...funnelChart,
      },
    ],
  }

  return { chart1, chart2 }
}

export const usersFunelResultsSlice = createSlice({
  name: 'usersFunelResults',
  initialState,
  reducers: {
    setUsersFunelResults: (
      state,
      { payload }: PayloadAction<Array<KeyValueDoublePercentPair>>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setUsersFunelResults } = usersFunelResultsSlice.actions

export const { reducer: usersFunelResults } = usersFunelResultsSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  KeyValuePercentPair,
  UsersResultByProvinceStateType,
  UsersResultsByProvince,
} from '../../../../types/api'

import { chartConfig } from '../../../../constants/charts'
import { getXAxisLabels } from '../../../../utils'

const { chartColors, singleBar } = chartConfig

const initialState: UsersResultByProvinceStateType = {
  data: {
    total: 0,
    labels: [],
    datasets: [],
  },
}

function normalizeData(payload: UsersResultsByProvince) {
  const { total, provinces } = payload
  const labels = getXAxisLabels(provinces)
  const provincesListByKeys = provinces.reduce(
    (
      acc: { [key: string]: KeyValuePercentPair },
      curr: KeyValuePercentPair
    ) => {
      return { ...acc, [curr.key]: curr }
    },
    {}
  )
  const data = labels.map((label) => provincesListByKeys[label]?.value || 0)

  const raw = labels.map(
    (label) =>
      provincesListByKeys[label] || {
        key: label,
        value: 0,
        percent: 0,
      }
  )

  return {
    total,
    labels,
    datasets: [
      {
        data,
        raw,
        backgroundColor: chartColors.orange,
        hoverBackgroundColor: chartColors.orange,
        categoryPercentage: singleBar.categoryPercentage,
        barPercentage: singleBar.barPercentage,
      },
    ],
  }
}

export const usersResultByProvinceSlice = createSlice({
  name: 'usersResultByProvince',
  initialState,
  reducers: {
    setUsersResultByProvince: (
      state,
      { payload }: PayloadAction<UsersResultsByProvince>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setUsersResultByProvince } = usersResultByProvinceSlice.actions

export const { reducer: usersResultByProvince } = usersResultByProvinceSlice

import { configureStore } from '@reduxjs/toolkit'
import { loader } from './slices/loader'
import { exportRange } from './slices/exportRange'
import { connectOnCallTotalResults } from './slices/connectOnCallTotal'
import { questionnaireOverTimeResults } from './slices/questionnaireOverTime'
import { questionnaireByTypeResults } from './slices/questionnaireByTypeResults'
import { usersFunelResults } from './slices/userFunelResults'
import { deactivatedApp } from './slices/deactivatedApp'
import { appByLanguage } from './slices/appByLanguage'
import { usersResultByProvince } from './slices/byProvince/usersResult'
import { usersResultByAge } from './slices/byAge/usersResult'
import { appOpened } from './slices/appOpened'
import { timeGapFilter } from './slices/timeGapFilter'
import { toaster } from './slices/toasterSlice'
import { total } from './slices/totalSlice'
import { participantsByProvince } from './slices/byProvince/participantsByProvince'
import { participantsByAge } from './slices/byAge/participantsByAge'
import { positiveVsInvalidByProvince } from './slices/byProvince/positiveVsInvalidByProvince'
import { positiveVsInvalidByAge } from './slices/byAge/positiveVsInvalidByAge'
import { positivityRateByProvince } from './slices/byProvince/positivityRateByProvince'
import { positivityRateByAge } from './slices/byAge/positivityRateByAge'
import { participantsTestsByProvince } from './slices/byProvince/participantsTestsByProvince'
import { connectOnCallStatusResults } from './slices/connectOnCallByStatus'

export const store = configureStore({
  reducer: {
    toaster,
    total,
    participantsByProvince,
    participantsByAge,
    positiveVsInvalidByProvince,
    positiveVsInvalidByAge,
    positivityRateByProvince,
    positivityRateByAge,
    participantsTestsByProvince,
    timeGapFilter,
    appOpened,
    usersResultByAge,
    usersResultByProvince,
    appByLanguage,
    deactivatedApp,
    usersFunelResults,
    questionnaireByTypeResults,
    questionnaireOverTimeResults,
    connectOnCallTotalResults,
    connectOnCallStatusResults,
    exportRange,
    loader,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

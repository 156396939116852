import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LoaderStateType = {
  actions: { [key: string]: string }
}

const initialState: LoaderStateType = {
  actions: {},
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    addLoaderAction: (state, { payload }: PayloadAction<string>) => {
      state.actions[payload] = payload
    },
    deleteLoaderAction: (state, { payload }: PayloadAction<string>) => {
      delete state.actions[payload]
    },
  },
})

export const { addLoaderAction, deleteLoaderAction } = loaderSlice.actions

export const { reducer: loader } = loaderSlice

import { FromToBodyType } from '../../types/common'
import { concatParams } from '../../utils'
import { axiosInstance } from '../axiosInstence'

export async function getExportData() {
  const { data } = await axiosInstance.get('/dashboard/export', {
    responseType: 'blob',
  })

  return data
}

export async function getExportInvalidTestResultPicturesData(
  body?: FromToBodyType
) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get(
    `/dashboard/cassetteImages${params}`,
    {
      responseType: 'blob',
    }
  )

  return data
}

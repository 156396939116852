import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PositivityRateResults } from '../../../../types/api'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { getDatasetDataItem, getXAxisLabels } from '../../../../utils'

const { chartColors, doubleBar } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<string>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: PositivityRateResults) {
  const isPercentAsValue = true
  const labels = ['National', ...getXAxisLabels(data.positive)]
  const positiveData = [
    {
      key: 'National',
      value: 0,
      percent: data.national,
    },
    ...data.positive,
  ]
  const positive = getDatasetDataItem(
    positiveData,
    labels,
    'Total',
    chartColors.orange,
    isPercentAsValue
  )

  const dataset = {
    ...positive,
    ...doubleBar,
  }

  return {
    labels,
    datasets: [dataset],
  }
}

export const positivityRateByProvinceSlice = createSlice({
  name: 'positivityRateByProvince',
  initialState,
  reducers: {
    setPositivityRateByProvince: (
      state,
      { payload }: PayloadAction<PositivityRateResults>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setPositivityRateByProvince } =
  positivityRateByProvinceSlice.actions

export const { reducer: positivityRateByProvince } =
  positivityRateByProvinceSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { parse, format, add, isEqual, isToday } from 'date-fns'
import { LineChartDatasetType } from '../../../types/charts'
import { DatesResult } from '../../../types/api'
import { timeGaps } from '../../../constants/common'
import {
  addEmptyValuesToArray,
  fillDatesSpaces,
  getLabelsForChart,
  mergeDates,
  numberFilter,
} from '../../../utils'
import { chartConfig } from '../../../constants/charts'

export type AppOpenedStateType = {
  data: {
    stepSize: number
    labels: Array<Array<string> | number>
    datasets: Array<LineChartDatasetType>
  }
}

type PayloadType = { timeGap: number; data: DatesResult }

const initialState: AppOpenedStateType = {
  data: {
    stepSize: 0,
    labels: [],
    datasets: [],
  },
}

const { chartColors, tension, pointRadius } = chartConfig

function normalizeData({ data: initData, timeGap }: PayloadType) {
  const { days, total } = initData
  const isCurrent = timeGap === timeGaps.current
  const goToWeeks = isCurrent
  let merged = mergeDates(days)

  if (!isCurrent) {
    const isWeek = timeGap === timeGaps.recentWeek
    const bottomDate = parse(
      format(add(new Date(), { days: isWeek ? -7 : -30 }), 'yyyy.MM.dd'),
      'yyyy.MM.dd',
      new Date()
    )

    merged = mergeDates(days).filter((day) => day >= bottomDate)

    if (!isEqual(merged[0], bottomDate)) {
      merged.unshift(bottomDate)
    }
  }

  if (!isToday(merged[merged.length])) {
    merged.push(new Date())
  }

  const allDates = fillDatesSpaces(merged)
  const labels = getLabelsForChart(allDates, goToWeeks)
  const data = addEmptyValuesToArray(allDates, days, goToWeeks)

  const stepSize = (Math.ceil(Math.max(...data) / 10) * 10) / 2

  return {
    labels,
    stepSize,
    datasets: [
      {
        label: numberFilter(total),
        pointStyle: 'circle' as const,
        borderColor: chartColors.orange,
        fill: true,
        backgroundColor: chartColors.orange,
        tension,
        data,
        pointRadius,
        pointHoverBackgroundColor: chartColors.transparent,
        pointHoverBorderColor: chartColors.transparent,
      },
    ],
  }
}

export const appOpenedSlice = createSlice({
  name: 'appOpened',
  initialState,
  reducers: {
    setAppOpened: (state, { payload }: PayloadAction<PayloadType>) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setAppOpened } = appOpenedSlice.actions

export const { reducer: appOpened } = appOpenedSlice

import {
  isEqual,
  isToday,
  add,
  startOfMonth,
  endOfMonth,
  startOfYear,
} from 'date-fns'
import { Range } from 'react-date-range'

const dateInstance = new Date()
const today = add(dateInstance, { days: 0 })
const yesterday = add(dateInstance, { days: -1 })

function compareTwoDates(date1 = dateInstance, date2 = dateInstance) {
  return isEqual(date1.getTime(), date2.getTime())
}

const staticRanges = [
  {
    label: 'Today',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return isToday(startDate) && isToday(endDate)
      }
      return false
    },
    range: () => {
      return {
        startDate: today,
        endDate: today,
        key: 'selection',
      }
    },
  },
  {
    label: 'Yesterday',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return (
          compareTwoDates(startDate, yesterday) &&
          compareTwoDates(endDate, yesterday)
        )
      }
      return false
    },
    range: () => {
      return {
        startDate: yesterday,
        endDate: yesterday,
        key: 'selection',
      }
    },
  },
  {
    label: 'Recent week',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return (
          compareTwoDates(startDate, add(dateInstance, { weeks: -1 })) &&
          compareTwoDates(endDate, yesterday)
        )
      }
      return false
    },
    range: () => {
      return {
        startDate: add(dateInstance, { weeks: -1 }),
        endDate: yesterday,
        key: 'selection',
      }
    },
  },
  {
    label: 'Recent Month',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return (
          compareTwoDates(startDate, add(dateInstance, { months: -1 })) &&
          compareTwoDates(endDate, yesterday)
        )
      }
      return false
    },
    range: () => {
      return {
        startDate: add(dateInstance, { months: -1 }),
        endDate: yesterday,
        key: 'selection',
      }
    },
  },
  {
    label: 'Last Month',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return (
          compareTwoDates(
            startDate,
            startOfMonth(add(dateInstance, { months: -1 }))
          ) &&
          compareTwoDates(
            endDate,
            endOfMonth(add(dateInstance, { months: -1 }))
          )
        )
      }
      return false
    },
    range: () => {
      return {
        startDate: startOfMonth(add(dateInstance, { months: -1 })),
        endDate: endOfMonth(add(dateInstance, { months: -1 })),
        key: 'selection',
      }
    },
  },
  {
    label: 'Current Year',
    isSelected: ({ startDate, endDate }: Range) => {
      if (startDate && endDate) {
        return (
          compareTwoDates(startDate, startOfYear(today)) &&
          compareTwoDates(endDate, yesterday)
        )
      }
      return false
    },
    range: () => {
      return {
        startDate: startOfYear(today),
        endDate: yesterday,
        key: 'selection',
      }
    },
  },
]

export { staticRanges, dateInstance }

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  accessTokenStorageKey,
  toasterTypes,
  toasterPositions,
} from '../../constants/common'

import { login } from '../../services/userApi'
import { LoginThunkPayloadType } from '../../types/user'
import { addToaster } from '../slices/toasterSlice'

export const loginThunk = createAsyncThunk<void, LoginThunkPayloadType>(
  'user/login',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    try {
      const data = await login(body)
      sessionStorage.setItem(accessTokenStorageKey, data.access_token)
      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      thunkAPI.dispatch(
        addToaster({
          id: Date.now().toString(),
          type: toasterTypes.danger,
          position: toasterPositions.topEnd,
          text: 'You have entered not correct credentials',
          header: 'Error while login',
        })
      )
    }
  }
)

import React from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useFormik, FormikProps } from 'formik'
import { loginFormConfig } from '../../constants/forms'
import { FormField } from '../FormField'
import { PlazusButton } from '../PlazusButton'
import { useValidation } from '../../hooks/useValidation'
import { useAppDispatch } from '../../redux/hooks'
import { loginThunk } from '../../redux/thunks/login'
import { routes } from '../../routing'

const initialValues = {
  username: '',
  password: '',
}

const requiredFields = ['username', 'password']

type LoginFormType = {
  username: string
  password: string
  [key: string]: string
}

export function LoginForm() {
  const navigate = useNavigate()

  const { validationSchema } = useValidation({ requiredFields })

  const dispatch = useAppDispatch()
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }: FormikProps<LoginFormType> = useFormik<LoginFormType>({
    initialValues,
    validationSchema,
    onSubmit,
  })

  function onSubmit() {
    const payload = {
      body: values,
      onSuccess: () => {
        navigate(routes.dashboard.path)
      },
    }
    dispatch(loginThunk(payload))
  }

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {Object.values(loginFormConfig).map((fieldProps) => {
        const { name } = fieldProps
        return (
          <FormField
            key={name}
            {...fieldProps}
            name={name}
            value={values[name]}
            error={errors[name]}
            touched={touched[name]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )
      })}
      <div className="login-form__submit d-flex justify-content-center">
        <PlazusButton
          text="Log in"
          visualType="outlined"
          type="submit"
          className="login-button"
        />
      </div>
      {/* for simplify development only */}
      {process.env.REACT_APP_NODE_ENV === 'development' && (
        <button
          type="button"
          onClick={() => {
            dispatch(
              loginThunk({
                body: {
                  username: 'test.user001@gmail.com',
                  password: 'K0cf7e3X7Ez2ZawZTeuN*',
                },
                onSuccess: () => {
                  navigate(routes.dashboard.path)
                },
              })
            )
          }}
        >
          DEV LOGIN
        </button>
      )}
    </Form>
  )
}

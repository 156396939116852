import React, { useState, useEffect } from 'react'
import { add, endOfMonth, startOfMonth } from 'date-fns'
import { Range, DateRangePicker, RangeKeyDict } from 'react-date-range'
import { FormField } from '../FormField'
import { staticRanges, dateInstance } from './staticRange'
import { PlazusButton } from '../PlazusButton'
import { formatDate } from '../../utils'
import './index.scss'

const displayDateFormat = 'dd-MM-yyyy'
const minDate: Date = new Date(2021, 5, 1)
const maxDate: Date = add(new Date(), { days: 0 })

const defaultDangeStart: Date = startOfMonth(add(dateInstance, { months: -1 }))
const defaultDangeEnd: Date = endOfMonth(add(dateInstance, { months: -1 }))

type PropsType = {
  onCancel: () => void
  onApply: (range: Range) => void
}

export function DateRangeSelector({ onCancel, onApply }: PropsType) {
  const [showCakendar, setShowCalendar] = useState(false)

  const [rangeState, setRangeState] = useState<Range>({
    startDate: defaultDangeStart,
    endDate: defaultDangeEnd,
    key: 'selection',
  })

  const [formattedDates, setFormattedDates] = useState({
    formattedStartDate: formatDate(defaultDangeStart, displayDateFormat),
    formattedEndDate: formatDate(defaultDangeStart, displayDateFormat),
  })

  function openCalendar() {
    setShowCalendar(true)
  }

  useEffect(() => {
    const { startDate, endDate } = rangeState
    if (startDate && endDate) {
      setFormattedDates({
        formattedStartDate: formatDate(startDate, displayDateFormat),
        formattedEndDate: formatDate(endDate, displayDateFormat),
      })
    }
  }, [rangeState])

  return (
    <div className="calendar">
      <div className="calendar__input">
        <FormField
          name="dateRange"
          type="string"
          value={`${formattedDates.formattedStartDate} - ${formattedDates.formattedEndDate}`}
          placeholder="Please specify a date range"
          label="Please specify a date range"
          readOnly={true}
          onClick={openCalendar}
        />
      </div>
      {showCakendar ? (
        <div className="date-range-wrapper">
          <DateRangePicker
            minDate={minDate}
            maxDate={maxDate}
            showMonthAndYearPickers={false}
            months={2}
            ranges={[rangeState]}
            direction="horizontal"
            staticRanges={staticRanges}
            onChange={(item: RangeKeyDict) => {
              setRangeState(item.selection)
            }}
            inputRanges={[]}
            showMonthArrow={true}
            weekStartsOn={1}
            showDateDisplay={false}
          />
          <div className="date-range-value-preview"></div>
          <div className="date-range-footer d-flex justify-content-end align-items-center p-2">
            <div className="date-range-footer-value">
              {formattedDates.formattedStartDate} -{' '}
              {formattedDates.formattedEndDate}
            </div>
            <div className="date-range-footer-buttons">
              <PlazusButton
                text="Cancel"
                type="button"
                visualType="filled"
                className="date-range-button date-range-cancel"
                onClick={() => {
                  setShowCalendar(false)
                  onCancel()
                }}
              />
              <PlazusButton
                text="Apply"
                type="button"
                visualType="filled"
                className="date-range-button date-range-apply"
                onClick={() => {
                  onApply(rangeState)
                  setShowCalendar(false)
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

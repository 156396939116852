import React from 'react'
import { TooltipItem } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { TestsByProvinceDatasetItemType } from '../../../types/charts'
import { chartConfig } from '../../../constants/charts'

const { chartColors, font } = chartConfig

type PropsType = {
  data: {
    labels: Array<string>
    datasets: Array<TestsByProvinceDatasetItemType>
  }
  legendTitle: string
}

export function StackbarChart({ data, legendTitle }: PropsType) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      datalabels: {
        color: chartColors.transparent,
      },
      legend: {
        align: 'center' as const,
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          color: chartColors.black,
          font: { family: font, size: 14 },
          boxWidth: 8,
          boxHeight: 8,
        },
      },
      title: {
        display: true,
        text: legendTitle,
        font: { family: font, size: 14 },
        color: chartColors.black,
      },
      tooltip: {
        callbacks: {
          title: () => '',
          afterLabel: () => '',
          label: (tooltipItem: TooltipItem<'bar'>) => {
            return `${Math.round(
              data.datasets[tooltipItem.datasetIndex].data[
                tooltipItem.dataIndex
              ] || 0
            )}%`
          },
        },
        backgroundColor: chartColors.white,
        bodyColor: chartColors.black,
        bodyFont: { family: font, size: 14 },
        displayColors: true,
        caretPadding: 5,
        mode: 'x' as const,
        cornerRadius: 3,
        borderColor: chartColors.greyTooltip,
        borderWidth: 1,
      },
    },
    scales: {
      yAxis: {
        display: false,
        stacked: true,
      },
      xAxis: {
        stacked: true,
        ticks: {
          font: { family: font, size: 14 },
          padding: 11,
          color: chartColors.black,
        },
        min: 0,
        max: 200,
        grid: {
          display: false,
        },
      },
    },
  }

  return <Bar height={350} width={1056} options={options} data={data} />
}

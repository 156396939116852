import React, { useMemo, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { Context } from 'chartjs-plugin-datalabels'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { chartConfig } from '../../../constants/charts'
import { percentFilter } from '../../../utils'
import { KeyValuePercentPair } from '../../../types/api'
import { HorisontalBarChartUnionType } from '../../../types/charts'

const { chartColors, font } = chartConfig

type PropsType<T> = {
  data: T
  legendTitle: string
  dalaLabelValueVsPercent?: boolean
  isPercentFirst?: boolean
}

export function HorizontalBarChart<T extends HorisontalBarChartUnionType>({
  data,
  legendTitle,
  dalaLabelValueVsPercent,
  isPercentFirst,
}: PropsType<T>) {
  const chartRef = useRef<ChartJSOrUndefined<'bar', number[], string> | null>()

  const labelValueLength = useMemo(() => {
    if (!data.labels || !data.labels.length) return {}
    const labelMaxLenght =
      Math.max(...data.labels.map((label: string) => Number(label.length))) * 7
    const valueLabelLength = 110
    return {
      labelMaxLenght,
      valueLabelLength,
    }
  }, [data])

  const maxValue = useMemo(() => {
    const { labelMaxLenght, valueLabelLength } = labelValueLength
    if (!labelMaxLenght || !valueLabelLength) return 0
    const chartWidth = chartRef?.current?.width || 0
    return (
      Math.max(...data.datasets[0].data) *
      (1 + (valueLabelLength + labelMaxLenght) / (chartWidth / 2))
    )
  }, [labelValueLength])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    interaction: {
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      datalabels: {
        display: true,
        color: chartColors.grey,
        font: { family: font, size: 12, weight: 400 },
        anchor: 'end' as const,
        align: 'end' as const,
        offset: 5,
        formatter: (value: number, context: Context) => {
          const {
            // @ts-ignore
            dataset: { raw },
          } = context
          if (dalaLabelValueVsPercent) {
            const rawData = raw.find(
              (rawItem: KeyValuePercentPair) => rawItem.value === value
            )
            if (isPercentFirst) {
              return `${percentFilter(rawData.percent)} (${value})`
            }
            return `${value} (${percentFilter(rawData.percent)})`
          }
          return value
        },
      },
      legend: {
        display: false,
        align: 'center' as const,
        position: 'top' as const,
        maxHeight: 50,
        labels: {
          usePointStyle: true,
          color: chartColors.black,
          font: { family: font, size: 14 },
          boxWidth: 8,
          boxHeight: 8,
        },
      },
      title: {
        display: true,
        text: legendTitle,
        font: { family: font, size: 14 },
        color: chartColors.black,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      yAxis: {
        display: true,
        ticks: {
          font: { family: font, size: 14 },
          color: chartColors.black,
          padding: 11,
        },
        min: 0,
        grid: {
          display: false,
        },
      },
      xAxis: {
        display: false,
        max: maxValue,
      },
    },
  }

  return <Bar ref={chartRef} options={options} data={data} />
}

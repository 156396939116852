import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { getTimeGap } from '../selectors'
import { FromToBodyType } from '../../types/common'
import {
  getParticipantsResultsByAge,
  getParticipantsResultsByProvince,
  getPositiveVsInvalidResultsByAge,
  getPositiveVsInvalidResultsByProvince,
  getTotalPositiveAndInvalidResults,
  getPositivityRateResultsByProvince,
  getPositivityRateResultsByAge,
  getParticipantTestsResultsByProvince,
  getAppOpenedResults,
  getUsersResultsByAge,
  getUsersResultsByProvince,
  getAppByLanguageResults,
  getDeactivatedAppsResults,
  getUsersFunnelResults,
  getQuestionnaireByTypeResults,
  getQuestionnaireOverTimeResults,
  getConnectOnCallTotalResults,
  getConnectOnCallByStatusResults,
} from '../../services/dashboardApi.ts'
import { setTotalPositiveAndInvalidResults } from '../slices/totalSlice'
import { setNegativeByProvince } from '../slices/byProvince/participantsByProvince'
import { setNegativeByAge } from '../slices/byAge/participantsByAge'
import { setPositiveVsInvalidByProvince } from '../slices/byProvince/positiveVsInvalidByProvince'
import { setPositiveVsInvalidByAge } from '../slices/byAge/positiveVsInvalidByAge'
import { setPositivityRateByProvince } from '../slices/byProvince/positivityRateByProvince'
import { setPositivityRateByAge } from '../slices/byAge/positivityRateByAge'
import { setParticipantsTestsByProvince } from '../slices/byProvince/participantsTestsByProvince'
import { setAppOpened } from '../slices/appOpened'
import { setUsersResultByAge } from '../slices/byAge/usersResult'
import { setUsersResultByProvince } from '../slices/byProvince/usersResult'
import { setAppByLanguage } from '../slices/appByLanguage'
import { setDeactivatedApp } from '../slices/deactivatedApp'
import { setUsersFunelResults } from '../slices/userFunelResults'
import { setQuestionnaireByTypeResults } from '../slices/questionnaireByTypeResults'
import { setQuestionnaireOverTimeResults } from '../slices/questionnaireOverTime'
import { setConnectOnCallTotalResults } from '../slices/connectOnCallTotal'
import { setConnectOnCallStatusResults } from '../slices/connectOnCallByStatus'
import { addLoaderAction, deleteLoaderAction } from '../slices/loader'
import { catchAuthError } from '../../utils'

export type FromToThunkPayloadType = {
  body?: FromToBodyType
  onSuccess?: () => void
  onError?: () => void
}

export const fetchTotalPositiveAndInvalidResults = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/TotalPositiveAndInvalidResults',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('TotalPositiveAndInvalidResults'))
    try {
      const data = await getTotalPositiveAndInvalidResults(body)
      thunkAPI.dispatch(setTotalPositiveAndInvalidResults(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('TotalPositiveAndInvalidResults'))
    }
  }
)

export const fetchParticipantsResultsByProvince = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/ParticipantsResultsByProvince',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('ParticipantsResultsByProvince'))
    try {
      const data = await getParticipantsResultsByProvince(body)
      thunkAPI.dispatch(setNegativeByProvince(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('ParticipantsResultsByProvince'))
    }
  }
)

export const fetchParticipantsResultsByAge = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/ParticipantsResultsByAge',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('ParticipantsResultsByAge'))
    try {
      const data = await getParticipantsResultsByAge(body)
      thunkAPI.dispatch(setNegativeByAge(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('ParticipantsResultsByAge'))
    }
  }
)

export const fetchPositiveVsInvalidResultsByProvince = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/PositiveVsInvalidResultsByProvince',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('PositiveVsInvalidResultsByProvince'))
    try {
      const data = await getPositiveVsInvalidResultsByProvince(body)
      thunkAPI.dispatch(setPositiveVsInvalidByProvince(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(
        deleteLoaderAction('PositiveVsInvalidResultsByProvince')
      )
    }
  }
)

export const fetchPositiveVsInvalidResultsByAge = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/PositiveVsInvalidResultsByAge',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('PositiveVsInvalidResultsByAge'))
    try {
      const data = await getPositiveVsInvalidResultsByAge(body)
      thunkAPI.dispatch(setPositiveVsInvalidByAge(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('PositiveVsInvalidResultsByAge'))
    }
  }
)

export const fetchPositivityRateByProvince = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/PositivityRateByProvince',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('PositivityRateByProvince'))
    try {
      const data = await getPositivityRateResultsByProvince(body)
      thunkAPI.dispatch(setPositivityRateByProvince(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('PositivityRateByProvince'))
    }
  }
)

export const fetchPositivityRateByAge = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/PositivityRateByAge',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('PositivityRateByAge'))
    try {
      const data = await getPositivityRateResultsByAge(body)
      thunkAPI.dispatch(setPositivityRateByAge(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('PositivityRateByAge'))
    }
  }
)

export const fetchParticipantTestsResultsByProvince = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/ParticipantTestsResultsByProvince',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('ParticipantTestsResultsByProvince'))
    try {
      const data = await getParticipantTestsResultsByProvince(body)
      thunkAPI.dispatch(setParticipantsTestsByProvince(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('ParticipantTestsResultsByProvince'))
    }
  }
)

export const fetchAppOpened = createAsyncThunk<void, FromToThunkPayloadType>(
  'dashboard/appOpened',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('appOpened'))
    try {
      const data = await getAppOpenedResults(body)
      const timeGap = getTimeGap(thunkAPI.getState() as RootState)
      thunkAPI.dispatch(
        setAppOpened({
          data,
          timeGap,
        })
      )

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('appOpened'))
    }
  }
)

export const fetchUsersResultByAge = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/UsersResultByAge',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('UsersResultByAge'))
    try {
      const data = await getUsersResultsByAge(body)
      thunkAPI.dispatch(setUsersResultByAge(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('UsersResultByAge'))
    }
  }
)

export const fetchUsersResultByProvince = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/UsersResultByProvince',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('UsersResultByProvince'))
    try {
      const data = await getUsersResultsByProvince(body)
      thunkAPI.dispatch(setUsersResultByProvince(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('UsersResultByProvince'))
    }
  }
)

export const fetchAppByLanguage = createAsyncThunk<void>(
  'dashboard/appByLanguage',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('appByLanguage'))
    try {
      const data = await getAppByLanguageResults()
      thunkAPI.dispatch(setAppByLanguage(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('appByLanguage'))
    }
  }
)

export const fetchDeactivatedApp = createAsyncThunk<void>(
  'dashboard/deactivatedApp',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('deactivatedApp'))
    try {
      const data = await getDeactivatedAppsResults()
      thunkAPI.dispatch(setDeactivatedApp(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('deactivatedApp'))
    }
  }
)

export const fetchUsersFunelResults = createAsyncThunk<void>(
  'dashboard/UsersFunelResulte',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('UsersFunelResulte'))
    try {
      const data = await getUsersFunnelResults()
      thunkAPI.dispatch(setUsersFunelResults(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('UsersFunelResulte'))
    }
  }
)

export const fetchQuestionnaireByTypeResults = createAsyncThunk<
  void,
  FromToThunkPayloadType
>(
  'dashboard/questionnaireByTypeResults',
  async ({ body, onSuccess, onError }, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('questionnaireByTypeResults'))
    try {
      const data = await getQuestionnaireByTypeResults(body)
      thunkAPI.dispatch(setQuestionnaireByTypeResults(data))

      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      if (onError) onError()
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('questionnaireByTypeResults'))
    }
  }
)

export const fetchQuestionnaireOverTimeResults = createAsyncThunk<void>(
  'dashboard/questionnaireOverTimeResults',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('questionnaireOverTimeResults'))
    try {
      const data = await getQuestionnaireOverTimeResults()
      thunkAPI.dispatch(setQuestionnaireOverTimeResults(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('questionnaireOverTimeResults'))
    }
  }
)

export const fetchConnectOnCallTotalResults = createAsyncThunk<void>(
  'dashboard/ConnectOnCallTotalResults',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('ConnectOnCallTotalResults'))
    try {
      const data = await getConnectOnCallTotalResults()
      thunkAPI.dispatch(setConnectOnCallTotalResults(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('ConnectOnCallTotalResults'))
    }
  }
)

export const fetchConnectOnCallStatusResults = createAsyncThunk<void>(
  'dashboard/ConnectOnCallStatusResults',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(addLoaderAction('ConnectOnCallStatusResults'))
    try {
      const data = await getConnectOnCallByStatusResults()
      thunkAPI.dispatch(setConnectOnCallStatusResults(data))
    } catch (e: unknown) {
      catchAuthError(e, thunkAPI.dispatch)
    } finally {
      thunkAPI.dispatch(deleteLoaderAction('ConnectOnCallStatusResults'))
    }
  }
)

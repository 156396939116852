import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import {
  getQuestionnaireByTimeResults,
  getQuestionnaireByTypeResults,
} from '../../../redux/selectors'
import { getHorizontalBarChartStyle } from '../../../utils/styleUtils'
import { HorizontalBarChart } from '../../charts/HorizontalBarChart'
import { LineChart } from '../../charts/LineChart'
import { Note } from '../../Note'

export function QuestionareResult() {
  const questionnaireByTypeResults = useAppSelector(
    getQuestionnaireByTypeResults
  )

  const questionnaireByTimeResults = useAppSelector(
    getQuestionnaireByTimeResults
  )

  return (
    <Container fluid={true} className="graph-container">
      <Row>
        <Col sm={12} md={6}>
          <div
            style={getHorizontalBarChartStyle(
              questionnaireByTypeResults.labels.length
            )}
          >
            <HorizontalBarChart
              data={questionnaireByTypeResults}
              legendTitle={`Surveys Completed by Type ${
                questionnaireByTypeResults.hasDatByType
                  ? ''
                  : ' (No Data Available)'
              }`}
              dalaLabelValueVsPercent={true}
            />
          </div>
          <div>
            <Note
              text="Note: (%) are the number of participants completing the survey divided by the total number of participants who completed a pre-test survey"
              align="left"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <LineChart
            height={200}
            data={questionnaireByTimeResults}
            legendTitle={`Surveys Completed Over Time ${
              questionnaireByTimeResults.hasDataByTime
                ? ''
                : ' (No Data Available)'
            }`}
          />
        </Col>
      </Row>
    </Container>
  )
}

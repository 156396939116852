import React, { useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useAppDispatch } from '../../redux/hooks'
import { deleteToasterById } from '../../redux/slices/toasterSlice'
import './index.scss'

const delay = 5000

type PropsType = {
  id: string
  header: string
  text: string
  type: 'danger'
  position: 'top-end' | 'bottom-end'
}

export function PlazusToaster({ type, position, text, header, id }: PropsType) {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(true)

  function onClose() {
    setShow(false)
    dispatch(deleteToasterById(id))
  }

  return (
    <ToastContainer position={position} className="p-2">
      <Toast bg={type} onClose={onClose} show={show} delay={delay} autohide>
        <Toast.Header className="justify-content-between">
          <strong>{header}</strong>
        </Toast.Header>
        <Toast.Body>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

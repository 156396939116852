import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { PositiveVsInvalidResults } from '../../../../types/api'
import { getDatasetDataItem, getXAxisLabelsWithAge } from '../../../../utils'

const { chartColors } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<Array<string>>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: PositiveVsInvalidResults) {
  const labels = getXAxisLabelsWithAge()

  const positive = getDatasetDataItem(
    data.positive,
    labels,
    'Positive',
    chartColors.blue
  )

  const invalid = getDatasetDataItem(
    data.invalid,
    labels,
    'Invalid',
    chartColors.yellow
  )

  return {
    labels,
    datasets: [positive, invalid],
  }
}

export const positiveVsInvalidByAgeSlice = createSlice({
  name: 'positiveVsInvalidByAge',
  initialState,
  reducers: {
    setPositiveVsInvalidByAge: (
      state,
      { payload }: PayloadAction<PositiveVsInvalidResults>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setPositiveVsInvalidByAge } = positiveVsInvalidByAgeSlice.actions

export const { reducer: positiveVsInvalidByAge } = positiveVsInvalidByAgeSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TotalPositiveAndInvalidResultsResponce } from '../../../types/api'

type TotalStateType = {
  totalPositiveAndInvalidResults: TotalPositiveAndInvalidResultsResponce
}

const initialState: TotalStateType = {
  totalPositiveAndInvalidResults: {
    invalid: {
      percent: 0,
      total: 0,
    },
    positive: {
      percent: 0,
      total: 0,
    },
    negative: {
      percent: 0,
      total: 0,
    },
  },
}

export const totalSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {
    setTotalPositiveAndInvalidResults: (
      state,
      { payload }: PayloadAction<TotalPositiveAndInvalidResultsResponce>
    ) => {
      state.totalPositiveAndInvalidResults.invalid =
        payload.invalid || initialState.totalPositiveAndInvalidResults.invalid
      state.totalPositiveAndInvalidResults.positive =
        payload.positive || initialState.totalPositiveAndInvalidResults.positive
      state.totalPositiveAndInvalidResults.negative =
        payload.negative || initialState.totalPositiveAndInvalidResults.negative
    },
  },
})

export const { setTotalPositiveAndInvalidResults } = totalSlice.actions

export const { reducer: total } = totalSlice

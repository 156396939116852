import React from 'react'
import { Container } from 'react-bootstrap'
import { useAppSelector } from '../../redux/hooks'
import { getToasters, isLoading } from '../../redux/selectors'
import { LoaderPortal } from '../Loader/LoaderPortal'
import { PlazusHeader } from '../PlazusHeader'
import { PlazusToaster } from '../PlazusToaster'

type PropsType = {
  isWithHeader: boolean
  children: React.ReactNode
}

export function Layout({ children, isWithHeader }: PropsType) {
  const toasters = useAppSelector(getToasters)
  const showLoader = useAppSelector(isLoading)

  return (
    <Container fluid={true} className="page my-auto p-0">
      <LoaderPortal show={showLoader} />
      {toasters.map((toasterProps) => (
        <PlazusToaster key={toasterProps.id} {...toasterProps} />
      ))}
      {isWithHeader ? <PlazusHeader /> : null}
      {children}
    </Container>
  )
}

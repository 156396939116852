import React from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { AppRouter } from './routing'
import { store } from './redux/store'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
  annotationPlugin
)

root.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>
)

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import { getUsersFunelResults } from '../../../redux/selectors'
import { getHorizontalBarChartStyle } from '../../../utils/styleUtils'
import { FunnelChart } from '../../charts/FunnelChart'
import { Note } from '../../Note'

export function UserEngagementFunnel() {
  const funnelChartData = useAppSelector(getUsersFunelResults)
  return (
    <Container fluid={true} className="graph-container">
      <Row>
        <Col
          sm={12}
          style={getHorizontalBarChartStyle(
            funnelChartData.chart1.labels.length + 1
          )}
        >
          <FunnelChart
            legendTitle="User Engagement Funnel (all steps following participant’s first test)"
            data={funnelChartData.chart1}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div>
            <p className="funnel-separation-line">
              Here down follows participants first test (additional tests not
              covered in this funnel)
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          sm={12}
          style={getHorizontalBarChartStyle(
            funnelChartData.chart2.labels.length + 1
          )}
        >
          <FunnelChart data={funnelChartData.chart2} />
        </Col>
      </Row>
      <Row>
        <Note
          align="left"
          text="Note: % at end of the bar are the number of participants completing this step divided by the total of participants in the proceeding step. (%) are the percentage of the people in that step divided by the total number of participants who opened app (first bar)."
        />
      </Row>
    </Container>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { add } from 'date-fns'
import { formatDate } from '../../../utils'

type ToasterStateType = {
  data: {
    timeGap: number
    startDate: string | null
    endDate: string | null
  }
}

const initialState: ToasterStateType = {
  data: {
    timeGap: 0,
    startDate: null,
    endDate: null,
  },
}

const endDate = add(new Date(), { days: -1 })

function getGapData(value: number) {
  let startDate: Date | null = null
  if (value === 1) {
    startDate = add(new Date(), { weeks: -1 })
  } else if (value === 2) {
    startDate = add(new Date(), { months: -1 })
  } else {
    startDate = null
  }
  return {
    timeGap: value,
    endDate: startDate ? formatDate(endDate) : null,
    startDate: startDate ? formatDate(startDate) : null,
  }
}

export const timeGapFilterSlice = createSlice({
  name: 'timeGapFilter',
  initialState,
  reducers: {
    setTimeGap: (state, { payload }: PayloadAction<number>) => {
      state.data = getGapData(payload)
    },
  },
})

export const { setTimeGap } = timeGapFilterSlice.actions

export const { reducer: timeGapFilter } = timeGapFilterSlice

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useAppSelector } from '../../../redux/hooks'
import { getAppByLanguage, getDeactivatedApp } from '../../../redux/selectors'
import { numberFilter, percentFilter } from '../../../utils'
import { CardBox } from '../../CardBox'
import { DoughnutChart } from '../../charts/DoughnutChart'

export function AppByLanguage() {
  const appByLanguageChartData = useAppSelector(getAppByLanguage)
  const deactivatedApp = useAppSelector(getDeactivatedApp)
  return (
    <Container fluid={true} className="graph-container">
      <Row className="justify-content-around">
        <Col sm={12} md={5}>
          <DoughnutChart
            data={appByLanguageChartData}
            legendTitle="App by Language"
          />
        </Col>
        <Col
          sm={12}
          md={5}
          className=" d-flex align-items-center justify-content-center"
        >
          <CardBox
            mainText={deactivatedApp.deactivated}
            total={numberFilter(deactivatedApp.total)}
            title="Content Removed App from Phone"
            percent={percentFilter(deactivatedApp.percent)}
          />
        </Col>
      </Row>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { LoginForm } from '../../components/LoginForm'
import { accessTokenStorageKey } from '../../constants/common'
import { routes } from '../../routing'
import { PlazusIcon } from '../../components/PlazusIcon'
import './index.scss'

export function LoginPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const accessToken = sessionStorage.getItem(accessTokenStorageKey)
    setIsLoggedIn(!!accessToken)
  }, [])

  return isLoggedIn ? (
    <Navigate replace to={routes.dashboard.path} />
  ) : (
    <div className="login-page">
      <div className="login-page__content">
        <div className="login-page__icon">
          <PlazusIcon iconPath="images/logo.png" alt="Plaxus logo" />
        </div>
        <div className="login-page__form">
          <LoginForm />
        </div>
      </div>
    </div>
  )
}

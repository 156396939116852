export function getBarChartStyle(length: number) {
  let width = length * 86 + 24

  if (document.documentElement.clientWidth < width) {
    width = document.documentElement.clientWidth * 0.8
  } else if (width < 250) {
    width = 250
  }

  return {
    width: `${width}px`,
    height: '300px',
    position: 'relative' as const,
  }
}

export function getHorizontalBarChartStyle(length: number) {
  const height = Math.max(length * 30 - 14 + 30, 120)

  return {
    height: `${height}px`,
    position: 'relative' as const,
  }
}

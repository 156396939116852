import {
  ConnectOnCallTotalResult,
  KeyValueDoublePercentPair,
  DeactivatedAppsResults,
  AppByLanguageResult,
  UsersResultsByProvince,
  KeyValuePercentPair,
  DatesResult,
  ParticipantTestsResults,
  PositivityRateResults,
  PositiveVsInvalidResults,
  ParticipantsResultsResponse,
  TotalPositiveAndInvalidResultsResponce,
  ConnectOnCallByStatusResult,
} from '../../types/api'

import { FromToBodyType } from '../../types/common'
import { concatParams } from '../../utils'
import { axiosInstance } from '../axiosInstence'

export async function getTotalPositiveAndInvalidResults(body?: FromToBodyType) {
  const params = concatParams(body)
  const { data } =
    await axiosInstance.get<TotalPositiveAndInvalidResultsResponce>(
      `/dashboard/total${params}`
    )
  return data
}

export async function getParticipantsResultsByProvince(body?: FromToBodyType) {
  const params = concatParams(body)
  const { data } = await axiosInstance.get<ParticipantsResultsResponse>(
    `/dashboard/participantsByProvince${params}`
  )
  return data
}

export async function getParticipantsResultsByAge(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<ParticipantsResultsResponse>(
    `/dashboard/participantsByAge${params}`
  )
  return data
}

export async function getPositiveVsInvalidResultsByProvince(
  body?: FromToBodyType
) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<PositiveVsInvalidResults>(
    `/dashboard/positiveVsInvalidByProvince${params}`
  )
  return data
}

export async function getPositiveVsInvalidResultsByAge(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<PositiveVsInvalidResults>(
    `/dashboard/positiveVsInvalidByAge${params}`
  )
  return data
}

export async function getPositivityRateResultsByProvince(
  body?: FromToBodyType
) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<PositivityRateResults>(
    `/dashboard/positivityRateByProvince${params}`
  )
  return data
}

export async function getPositivityRateResultsByAge(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<PositivityRateResults>(
    `/dashboard/positivityRateByAge${params}`
  )
  return data
}

export async function getParticipantTestsResultsByProvince(
  body?: FromToBodyType
) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<ParticipantTestsResults>(
    `/dashboard/participantTestsByProvince${params}`
  )
  return data
}

export async function getAppOpenedResults(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<DatesResult>(
    `/dashboard/appOpened${params}`
  )

  return data
}

export async function getUsersResultsByAge(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<Array<KeyValuePercentPair>>(
    `/dashboard/usersByAge${params}`
  )

  return data
}

export async function getUsersResultsByProvince(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<UsersResultsByProvince>(
    `/dashboard/usersByProvince${params}`
  )

  return data
}

export async function getAppByLanguageResults() {
  const { data } = await axiosInstance.get<AppByLanguageResult>(
    '/dashboard/appByLanguage'
  )
  return data
}

export async function getDeactivatedAppsResults() {
  const { data } = await axiosInstance.get<DeactivatedAppsResults>(
    '/dashboard/deactivatedApps'
  )

  return data
}

export async function getUsersFunnelResults() {
  const { data } = await axiosInstance.get<Array<KeyValueDoublePercentPair>>(
    '/dashboard/usersFunnel'
  )

  return data
}

export async function getQuestionnaireByTypeResults(body?: FromToBodyType) {
  const params = concatParams(body)

  const { data } = await axiosInstance.get<Array<KeyValuePercentPair>>(
    `/dashboard/questionnaireByType${params}`
  )

  return data
}

export async function getQuestionnaireOverTimeResults() {
  const { data } = await axiosInstance.get<DatesResult>(
    '/dashboard/questionnaireOverTime'
  )
  return data
}

export async function getConnectOnCallTotalResults() {
  const { data } = await axiosInstance.get<ConnectOnCallTotalResult>(
    '/dashboard/connectOnCallTotal'
  )
  return data
}

export async function getConnectOnCallByStatusResults() {
  const { data } = await axiosInstance.get<ConnectOnCallByStatusResult>(
    '/dashboard/connectOnCallByStatus'
  )
  return data
}

import React from 'react'
import './index.scss'

type PropsType = {
  text: string
  align: 'left' | 'center'
}

export function Note({ text, align }: PropsType) {
  const classes = align === 'left' ? 'text-left' : 'text-center'
  return <p className={`note ${classes}`}>{text}</p>
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChartDatasetType } from '../../../../types/charts'
import { chartConfig } from '../../../../constants/charts'

import { ParticipantsResultsResponse } from '../../../../types/api'
import { getDatasetDataItem, getXAxisLabels } from '../../../../utils'

const { chartColors } = chartConfig

type TotalStateType = {
  data: {
    labels: Array<string>
    datasets: Array<ChartDatasetType>
  }
}

const initialState: TotalStateType = {
  data: {
    labels: [],
    datasets: [],
  },
}

function normalizeData(data: ParticipantsResultsResponse) {
  const labels = getXAxisLabels(data.total, data.negative)

  const negative = getDatasetDataItem(
    data.negative,
    labels,
    'Negative',
    chartColors.lightGreen
  )

  const total = getDatasetDataItem(
    data.total,
    labels,
    'Total',
    chartColors.orange
  )

  return {
    labels,
    datasets: [total, negative],
  }
}

export const participantsByProvinceSlice = createSlice({
  name: 'negativePositiveInvalidByProvince',
  initialState,
  reducers: {
    setNegativeByProvince: (
      state,
      { payload }: PayloadAction<ParticipantsResultsResponse>
    ) => {
      state.data = normalizeData(payload)
    },
  },
})

export const { setNegativeByProvince } = participantsByProvinceSlice.actions

export const { reducer: participantsByProvince } = participantsByProvinceSlice
